import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useCompany, useLoggedInUser } from '../../data';

import AddStreamModal from '../../components/modal/AddStreamModal';
import AuthService from '../../services/auth';
import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Container from '../../components/ui/Container';
import Datatable from '../../components/datatable';
import DeleteModal from '../../components/modal/DeleteModal';
import Dropzone from 'react-dropzone-uploader';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';
import PageError from '../../components/ui/PageError';
import PageHeader from '../../components/dashboard/PageHeader';
import PageLoader from '../../components/ui/PageLoader';
import { TwitterPicker } from 'react-color';
import { mutate } from 'swr';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { useState } from 'react';

export default function CompanyPage() {
  const history = useHistory();
  const { params } = useRouteMatch();

  const [deleteModal, setDeleteModal] = useState(false);
  const [addStreamModal, setAddStreamModal] = useState(false);

  const { loggedInUser } = useLoggedInUser();

  const { company, companyLoading, companyError } = useCompany(
    loggedInUser?.company?.id || params.client
  );

  const tableColumns = [
    {
      name: 'Naam',
      selector: 'name',
      sortable: true,
    },
  ];

  if (companyError) return <PageError />;
  if (companyLoading) return <PageLoader />;

  return (
    <>
      <AddStreamModal
        isOpen={addStreamModal}
        title={'Stream toevoegen'}
        onRequestClose={() => {
          setAddStreamModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/company/${company.id}`);
        }}
        company={company}
      />

      <DeleteModal
        isOpen={deleteModal}
        title={'Klant verwijderen'}
        onRequestClose={() => {
          setDeleteModal(false);
        }}
        onSubmit={async () => {
          try {
            await Axios.delete(`api/company/${company.id}`);

            toast.success(`Klant ${company.name} verwijderd`);

            setDeleteModal(false);
            history.push(`/dashboard/clients`);
          } catch (error) {}
        }}
      />

      {loggedInUser?.role === 'Admin' && (
        <PageHeader title={company.name}>
          <Button
            inline
            danger
            icon="Trash2"
            title="Verwijder"
            onClick={() => {
              setDeleteModal(true);
            }}
          />
        </PageHeader>
      )}

      <Container>
        <div className="w-full flex md:flex-row flex-col">
          <div className="md:w-1/2 w-full md:mr-5 md:mb-0 mr-0 mb-5">
            <Card>
              <Formik
                initialValues={company}
                enableReinitialize
                validate={(values) => {
                  const errors = {};

                  if (!values.name) {
                    errors.name = 'Dit veld is verplicht';
                  }

                  return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    await Axios.put(`api/company/${company.id}`, {
                      name: values.name,
                      logo: values.logo,
                      mainColor: values.mainColor,
                    });

                    mutate(
                      `${process.env.REACT_APP_API_URL}/api/company/${company.id}`
                    );

                    toast.success(
                      `${
                        loggedInUser?.role === 'Admin'
                          ? `Klant ${values.name} `
                          : 'Wijzigingen '
                      } opgeslagen`
                    );

                    resetForm({ values });
                  } catch (error) {}
                }}
              >
                {({ isSubmitting, dirty, values, setFieldValue }) => (
                  <Form>
                    {loggedInUser?.role === 'Admin' && (
                      <FormInputGroup>
                        <FormLabel>Naam</FormLabel>
                        <Field type="text" name="name" component={FormInput} />
                        <ErrorMessage name="name" component={FormError} />
                      </FormInputGroup>
                    )}

                    <FormInputGroup>
                      <FormLabel>
                        Logo
                        {!values.logo
                          ? ' ― .png, .jpg of .svg, maximaal 2 MB'
                          : ''}
                      </FormLabel>

                      {values.logo && (
                        <div className="w-full p-5 mb-2 rounded border border-solid border-black">
                          <img
                            src={`${process.env.REACT_APP_API_URL}/assets/${values.logo}`}
                            alt=""
                            className="h-14 w-full object-contain object-center"
                          />
                        </div>
                      )}

                      {values.logo && (
                        <Button
                          type="reset"
                          icon="Trash"
                          danger
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          title="Verwijder logo"
                          onClick={() => {
                            setFieldValue('logo', '');
                          }}
                        />
                      )}

                      <Field
                        type="text"
                        name="logo"
                        hidden
                        component={FormInput}
                      />
                      {!values.logo && (
                        <Dropzone
                          getUploadParams={({ meta }) => {
                            return {
                              url: `${process.env.REACT_APP_API_URL}/upload`,
                              headers: {
                                Authorization: `Bearer ${AuthService.getToken()}`,
                              },
                            };
                          }}
                          onChangeStatus={({ meta, file, xhr }, status) => {
                            if (xhr?.response) {
                              const { fileName } = JSON.parse(xhr.response);

                              if (status === 'done')
                                setFieldValue('logo', fileName);
                            }
                          }}
                          accept=".png, .jpg, .svg"
                          maxFiles={1}
                          multiple={false}
                          canCancel={false}
                          inputContent="Sleep een afbeelding hierheen of klik om te selecteren"
                          autoUpload={true}
                          maxSizeBytes={2097152}
                        />
                      )}
                    </FormInputGroup>

                    <FormInputGroup last>
                      <FormLabel>Kleur</FormLabel>
                      <Field
                        type="text"
                        name="mainColor"
                        hidden
                        component={FormInput}
                      />

                      <div
                        className="w-full h-10 rounded rounded-b-none border-l border-t border-r border-solid border-black"
                        style={{
                          backgroundColor: values.mainColor
                            ? values.mainColor
                            : '#000000',
                        }}
                      ></div>

                      <TwitterPicker
                        triangle="hide"
                        color={values.mainColor ? values.mainColor : '#000000'}
                        onChangeComplete={(color, event) => {
                          setFieldValue('mainColor', color.hex);
                        }}
                      />
                    </FormInputGroup>

                    {dirty && (
                      <Button
                        type="submit"
                        icon="Save"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        title="Opslaan"
                        marginTop
                      />
                    )}
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
          <div className="md:w-1/2 w-full">
            <Card
              header="Streams"
              buttons={
                loggedInUser?.role === 'Admin' && (
                  <Button
                    inline
                    icon="Plus"
                    title="Toevoegen"
                    onClick={() => {
                      setAddStreamModal(true);
                    }}
                  />
                )
              }
            >
              <Datatable
                columns={tableColumns}
                data={company.stream}
                progressPending={companyLoading}
                pointerOnHover
                noDataComponent={`Geen streams gevonden voor ${company.name}`}
                fixedHeader
                onRowClicked={(row) => {
                  history.push(`/dashboard/stream/${row.uuid}`);
                }}
              />
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
}
