import { ErrorMessage, Field, Form, Formik } from 'formik';

import { useCompanies } from '../../data';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormSelect from '../../components/ui/FormSelect';

import FormLabel from '../../components/ui/FormLabel';

import Modal from './index';
import toast from 'react-hot-toast';

export default function Component(props) {
  const { companies, companiesLoading } = useCompanies();

  return (
    <Modal {...props}>
      {props.returnbox && !companiesLoading && (
        <Formik
          initialValues={props.returnbox}
          enableReinitialize
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = 'This field is required';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              await Axios.put(`api/returnbox/${props.returnbox.uuid}`, {
                name: values.name,
                companyId: values.companyId,
              });

              toast.success(`Changes saved`);

              resetForm({ values });
            } catch (error) {}
          }}
        >
          {({ isSubmitting, dirty, values, setFieldValue }) => (
            <Form>
              <FormInputGroup>
                <FormLabel>Client</FormLabel>

                <Field as="select" name="companyId" component={FormSelect}>
                  <option value="null" selected>
                    ...
                  </option>

                  {companies.map((company) => {
                    return <option value={company.id}>{company.name}</option>;
                  })}
                </Field>
              </FormInputGroup>

              <FormInputGroup last>
                <FormLabel>Name</FormLabel>
                <Field type="text" name="name" component={FormInput} />
                <ErrorMessage name="name" component={FormError} />
              </FormInputGroup>

              {dirty && (
                <Button
                  type="submit"
                  icon="Save"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  title="Save"
                  marginTop
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
}
