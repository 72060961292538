import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useCompanies, useReturnBoxes } from '../../data';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';
import FormSelect from '../../components/ui/FormSelect';
import Modal from './index';
import toast from 'react-hot-toast';

export default function Component(props) {
  const { companies, companiesLoading } = useCompanies();
  const { returnboxes, returnboxesLoading } = useReturnBoxes();

  return (
    <Modal {...props}>
      {props.cambox && !companiesLoading && !returnboxesLoading && (
        <Formik
          initialValues={props.cambox}
          enableReinitialize
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = 'This field is required';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              await Axios.put(`api/cambox/${props.cambox.uuid}`, {
                name: values.name,
                wmtId: values.wmtId,
                returnboxId: values.returnboxId,
                companyId: values.companyId,
              });

              toast.success(`Changes saved`);

              resetForm({ values });
            } catch (error) {}
          }}
        >
          {({ isSubmitting, dirty, values, setFieldValue }) => (
            <Form>
              <FormInputGroup>
                <FormLabel>Client</FormLabel>

                <Field as="select" name="companyId" component={FormSelect}>
                  <option value="null" selected>
                    ...
                  </option>

                  {companies.map((company) => {
                    return <option value={company.id}>{company.name}</option>;
                  })}
                </Field>

                <ErrorMessage name="wmtId" component={FormError} />
              </FormInputGroup>

              <FormInputGroup>
                <FormLabel>Name</FormLabel>
                <Field type="text" name="name" component={FormInput} />
                <ErrorMessage name="name" component={FormError} />
              </FormInputGroup>

              <FormInputGroup>
                <FormLabel>ReturnBox</FormLabel>

                <Field as="select" name="returnboxId" component={FormSelect}>
                  <option value="" selected>
                    ...
                  </option>

                  {returnboxes.map((returnBox) => {
                    return (
                      <option value={returnBox.id}>{returnBox.name}</option>
                    );
                  })}
                </Field>

                <ErrorMessage name="returnboxId" component={FormError} />
              </FormInputGroup>

              {/* <FormInputGroup last>
                <FormLabel>WMT ID</FormLabel>
                <Field type="text" name="wmtId" component={FormInput} />
                <ErrorMessage name="wmtId" component={FormError} />
              </FormInputGroup> */}

              {dirty && (
                <Button
                  type="submit"
                  icon="Save"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  title="Save"
                  marginTop
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
}
