import 'react-dropzone-uploader/dist/styles.css';

import { Route, Switch } from 'react-router-dom';

import PageLoader from '../../components/ui/PageLoader';
import PageError from '../../components/ui/PageError';

import CompaniesPage from '../dashboard/CompaniesPage';
import CompanyPage from '../dashboard/CompanyPage';
import DashboardPage from '../dashboard/DashboardPage';

import CamBoxesPage from '../cambox/CamBoxesPage';

import Footer from '../../components/dashboard/Footer';
import Header from '../../components/dashboard/Header';
import StreamPage from '../dashboard/StreamPage';
import UsersPage from '../dashboard/UsersPage';

import { useApiStatus } from '../../data';
import CamBoxPage from '../cambox/CamBoxPage';

import ReturnBoxPage from '../returnbox/ReturnBoxPage';

import { SocketContext, socket } from '../../context/socket';

export default function App() {
  const { apiLoading, apiError } = useApiStatus();

  if (apiLoading) return <PageLoader />;
  if (apiError) return <PageError screen />;

  return (
    <>
      <Header />

      <main className="flex flex-grow flex-col">
        <SocketContext.Provider value={socket}>
          <Switch>
            <Route exact path="/dashboard" component={DashboardPage} />

            <Route exact path="/dashboard/cambox" component={CamBoxesPage} />
            <Route
              exact
              path="/dashboard/cambox/:cambox"
              component={CamBoxPage}
            />

            <Route
              exact
              path="/dashboard/returnbox/:returnbox"
              component={ReturnBoxPage}
            />

            <Route exact path="/dashboard/clients" component={CompaniesPage} />
            <Route
              exact
              path="/dashboard/client/:client"
              component={CompanyPage}
            />

            <Route exact path="/dashboard/users" component={UsersPage} />

            <Route
              exact
              path="/dashboard/stream/:stream"
              component={StreamPage}
            />
          </Switch>
        </SocketContext.Provider>
      </main>

      <Footer />
    </>
  );
}
