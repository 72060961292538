import { ErrorMessage, Field, Form, Formik } from 'formik';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';
import FormSelect from '../../components/ui/FormSelect';
import Modal from './index';
import toast from 'react-hot-toast';
import { useCompanies } from '../../data';

export default function Component(props) {
  const { companies, companiesLoading } = useCompanies();

  return (
    <Modal {...props}>
      {!companiesLoading && (
        <Formik
          initialValues={{ name: '', email: '', company: '' }}
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = 'Dit veld is verplicht';
            }

            if (!values.email) {
              errors.email = 'Dit veld is verplicht';
            }
            if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Ongeldig e-mailadres';
            }

            if (!values.company) {
              errors.company = 'Dit veld is verplicht';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await Axios.post('api/user', {
                name: values.name,
                email: values.email,
                companyId: values.company,
              });

              toast.success(`Gebruiker ${values.name} toegevoegd`);

              props.onRequestClose();
            } catch (error) {}
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormInputGroup>
                <FormLabel>Naam</FormLabel>
                <Field
                  type="text"
                  name="name"
                  autoFocus
                  component={FormInput}
                />
                <ErrorMessage name="name" component={FormError} />
              </FormInputGroup>

              <FormInputGroup>
                <FormLabel>E-mailadres</FormLabel>
                <Field type="email" name="email" component={FormInput} />
                <ErrorMessage name="email" component={FormError} />
              </FormInputGroup>

              <FormInputGroup>
                <FormLabel>Klant</FormLabel>
                <Field as="select" name="company" component={FormSelect}>
                  <option value="" disabled selected>
                    ...
                  </option>

                  {companies.map((company) => {
                    return <option value={company.id}>{company.name}</option>;
                  })}
                </Field>

                <ErrorMessage name="company" component={FormError} />
              </FormInputGroup>

              <Button
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                title="Gebruiker toevoegen"
              />
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
}
