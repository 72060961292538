import React, { useEffect, useState } from 'react';

import Block from '../../components/ui/Block';
import Footer from '../../components/live/Footer';
import Header from '../../components/live/Header';
import PasswordLogin from '../../components/live/PasswordLogin';
import PageError from '../../components/ui/PageError';
import PageLoader from '../../components/ui/PageLoader';
import VideoPlyr from '../../components/plyr';
// import { mutate } from 'swr';
import { useApiStatus, useLive } from '../../data';
import { useRouteMatch } from 'react-router-dom';

export default function LivePage() {
  const { params } = useRouteMatch();

  const [streamUrl, setStreamUrl] = useState(null);

  const { apiLoading, apiError } = useApiStatus();

  const { stream, streamLoading, streamError } = useLive(
    params.stream,
    params.viewer
  );

  useEffect(() => {
    if (stream) {
      document.title = `${stream.stream?.name} - ${stream.stream?.company?.name}`;

      if (stream.viewer && stream.viewer.signedStreamUrl)
        setStreamUrl(stream.viewer.signedStreamUrl);
    }
  }, [stream, streamUrl]);

  if (apiLoading) return <PageLoader />;
  if (apiError) return <PageError screen />;

  if (streamLoading) return <PageLoader screen />;
  if (streamError)
    return <PageError screen>De opgevraagde stream is niet gevonden</PageError>;

  return (
    <>
      <Header stream={stream} />

      <main className="flex flex-grow flex-col">
        {stream.viewer && (
          <>
            {(stream.stream.description ||
              (stream.stream.contactName && stream.stream.contactEmail)) && (
              <Block>
                {stream.stream.description && (
                  <div className="mr-2">{stream.stream.description}</div>
                )}

                {stream.stream.contactName && stream.stream.contactEmail && (
                  <div>
                    Neem voor vragen contact op met{' '}
                    <a
                      className="underline"
                      href={`mailto:${stream.stream.contactEmail}`}
                    >
                      {stream.stream.contactName}
                    </a>
                  </div>
                )}
              </Block>
            )}

            {stream.viewer.requiresPassword && !streamUrl && (
              <PasswordLogin
                stream={params.stream}
                viewer={params.viewer}
                setStreamUrl={setStreamUrl}
              />
            )}

            {streamUrl && (
              <Block className="px-0 py-0 rounded overflow-hidden">
                <VideoPlyr
                  src={streamUrl}
                  color={stream?.stream?.company?.mainColor}
                  onPlayerEnded={() => {
                    // mutate(
                    //   `${process.env.REACT_APP_API_URL}/api/live/${params.stream}/${params.viewer}`
                    // );
                  }}
                />
              </Block>
            )}
          </>
        )}

        {!stream.viewer && (
          <PageError>
            Het is op dit moment niet toegestaan mee te kijken naar deze stream.
            {stream.stream.contactName && stream.stream.contactEmail && (
              <>
                <br />
                Neem voor vragen contact op met{' '}
                <a
                  className="underline"
                  href={`mailto:${stream.stream.contactEmail}`}
                >
                  {stream.stream.contactName}
                </a>
                .
              </>
            )}
          </PageError>
        )}
      </main>

      <Footer />
    </>
  );
}
