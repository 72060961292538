import Block from '../../components/ui/Block';
import MenuLink from '../../components/ui/MenuLink';
import { useHistory } from 'react-router-dom';
import { useLoggedInUser } from '../../data';
import { useToasterStore } from 'react-hot-toast';

export default function Header() {
  const history = useHistory();
  const { toasts } = useToasterStore();

  const { loggedInUser, loggedInUserLoading } = useLoggedInUser();

  return (
    <>
      <div
        id="toasts-backdrop"
        className={`fixed top-0 w-screen h-40 bg-gradient-to-b from-gray-200 ease-in-out transition-transform ${
          toasts.length > 0 &&
          toasts.filter((toast) => toast.visible).length > 0
            ? ''
            : 'transform -translate-y-40'
        }`}
      ></div>

      {!loggedInUserLoading && (
        <Block className="md:mt-10 mt-5 shadow-lg">
          <div className="md:w-1/5 w-full flex md:justify-start justify-center">
            <img
              src="/assets/image/logo.svg"
              alt=""
              className="h-14 cursor-pointer"
              onClick={() => {
                history.push('/');
              }}
            />
          </div>

          <div className="md:w-4/5 md:mt-0 mt-4 w-full flex md:justify-end justify-center items-center md:flex-row flex-col">
            <ul className="flex md:justify-end items-center md:flex-row flex-col">
              <MenuLink
                href="/dashboard/cambox"
                title="Cambox"
                isActive={(match, location) => {
                  return (
                    location.pathname.startsWith('/dashboard/cambox') ||
                    location.pathname.startsWith('/dashboard/returnbox')
                  );
                }}
              />

              {loggedInUser.role === 'Admin' && (
                <>
                  <span className="md:block hidden ml-6"> ― </span>

                  <MenuLink
                    href="/dashboard/clients"
                    title="Klanten"
                    isActive={(match, location) => {
                      return (
                        location.pathname.startsWith('/dashboard/client') ||
                        location.pathname.startsWith('/dashboard/stream')
                      );
                    }}
                  />
                  <MenuLink
                    href="/dashboard/users"
                    title="Gebruikers"
                    isActive={(match, location) => {
                      return location.pathname.startsWith('/dashboard/user');
                    }}
                  />
                </>
              )}
              <span className="md:block hidden ml-6"> ― </span>

              <MenuLink href="/logout" title="Uitloggen" />
            </ul>

            <div className="md:flex hidden ml-6 justify-end items-center">
              <div className="lg:block hidden rounded-full h-10 w-10 bg-br-gold mr-6 text-center text-white uppercase leading-10">
                {loggedInUser.name?.substring(0, 2) ||
                  loggedInUser.email.substring(0, 2)}
              </div>

              <div className="flex flex-col text-right">
                <div className="">
                  {loggedInUser.name || loggedInUser.email}
                </div>
                <div className="">
                  {loggedInUser.company?.name || 'Broadcast Rental'}
                </div>
              </div>
            </div>
          </div>
        </Block>
      )}
    </>
  );
}
