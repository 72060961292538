import CompanyPage from '../dashboard/CompanyPage';
import PageHeader from '../../components/dashboard/PageHeader';
import { useLoggedInUser } from '../../data';

export default function DashboardPage() {
  const { loggedInUser, loggedInUserLoading } = useLoggedInUser();

  return (
    <>
      {!loggedInUserLoading && (
        <>
          <PageHeader
            title={`Welkom bij BCRFLEX, ${
              loggedInUser.name || loggedInUser.email
            }!`}
          />

          {loggedInUser.role !== 'Admin' && <CompanyPage />}
        </>
      )}
    </>
  );
}
