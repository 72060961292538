import React from 'react';
import AuthService from '../services/auth';
import socketIOClient from 'socket.io-client';

const getSocket = () => {
  const token = AuthService.getToken();

  if (token) {
    return socketIOClient.connect(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: false,
      query: { token },
    });
  }
};

export const socket = getSocket();
export const SocketContext = React.createContext();
