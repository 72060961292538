import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useLoggedInUser, useStream } from '../../data';

import AddViewerModal from '../../components/modal/AddViewerModal';
import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Container from '../../components/ui/Container';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Datatable from '../../components/datatable';
import DeleteModal from '../../components/modal/DeleteModal';
import EditViewerModal from '../../components/modal/EditViewerModal';
import EmailViewerModal from '../../components/modal/EmailViewerModal';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';
import PageError from '../../components/ui/PageError';
import PageHeader from '../../components/dashboard/PageHeader';
import PageLoader from '../../components/ui/PageLoader';
import UpdateViewerTimesModal from '../../components/modal/UpdateViewerTimesModal';
import { mutate } from 'swr';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { useState } from 'react';

export default function StreamPage() {
  const history = useHistory();
  const { params } = useRouteMatch();

  const [addViewerModal, setAddViewerModal] = useState(false);
  const [editViewerModal, setEditViewerModal] = useState(false);
  const [emailViewerModal, setEmailViewerModal] = useState(false);
  const [deleteStreamModal, setDeleteStreamModal] = useState(false);
  const [deleteViewerModal, setDeleteViewerModal] = useState(false);
  const [selectedViewer, setSelectedViewer] = useState(false);
  const [updateViewerTimesModal, setUpdateViewerTimesModal] = useState(false);

  const { stream, streamLoading, streamError } = useStream(params.stream);
  const { loggedInUser, loggedInUserLoading, loggedInUserError } =
    useLoggedInUser();

  const tableColumns = [
    {
      name: 'Naam',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Mag altijd meekijken',
      selector: 'allowAlways',
      sortable: true,
      format: (row) => {
        return row.allowAlways ? 'Ja' : 'Nee';
      },
    },
    {
      name: 'Wachtwoord',
      selector: 'passwordId',
      sortable: false,
      format: (row) => {
        return row.passwordId ? 'Ja' : 'Nee';
      },
    },
    {
      cell: (viewer) => (
        <>
          {!viewer.allowAlways && (
            <Button
              inline
              icon="Clock"
              className={`mr-2 ${
                viewer.viewerRights?.length > 0 ? 'bg-green-500' : ''
              }`}
              onClick={() => {
                setSelectedViewer(viewer);
                setUpdateViewerTimesModal(true);
              }}
            />
          )}

          <Button
            inline
            icon="Mail"
            className={`mr-2 ${viewer.emailSent ? 'bg-green-500' : ''}`}
            onClick={() => {
              setSelectedViewer(viewer);
              setEmailViewerModal(true);
            }}
          />

          <CopyToClipboard
            text={`${process.env.REACT_APP_URL}/live/${stream.uuid}/${viewer.token}`}
            onCopy={() => toast.success(`Link voor ${viewer.name} gekopieerd`)}
          >
            <Button inline icon="Copy" className="mr-2" />
          </CopyToClipboard>

          <Button
            danger
            inline
            icon="Trash2"
            onClick={() => {
              setSelectedViewer(viewer);
              setDeleteViewerModal(true);
            }}
          />
        </>
      ),
      right: true,
    },
  ];

  if (streamError || loggedInUserError) return <PageError />;
  if (streamLoading || loggedInUserLoading) return <PageLoader />;

  return (
    <>
      <DeleteModal
        isOpen={deleteStreamModal}
        title={'Stream verwijderen'}
        onRequestClose={() => {
          setDeleteStreamModal(false);
        }}
        onSubmit={async () => {
          try {
            await Axios.delete(`api/stream/${stream.id}`);

            toast.success(`Stream ${stream.name} verwijderd`);

            setDeleteStreamModal(false);
            history.push(`/dashboard/client/${stream.company.id}`);
          } catch (error) {}
        }}
      />

      <DeleteModal
        isOpen={deleteViewerModal}
        title={'Kijker verwijderen'}
        onRequestClose={() => {
          setDeleteViewerModal(false);
          setSelectedViewer(false);
        }}
        viewer={selectedViewer}
        onSubmit={async () => {
          try {
            await Axios.delete(`api/viewer/${selectedViewer.id}`, {
              data: { companyId: selectedViewer.companyId },
            });

            toast.success(`Kijker ${selectedViewer.name} verwijderd`);

            mutate(
              `${process.env.REACT_APP_API_URL}/api/stream/${stream.uuid}`
            );

            setDeleteViewerModal(false);
          } catch (error) {}
        }}
      />

      <AddViewerModal
        isOpen={addViewerModal}
        title={'Kijker toevoegen'}
        onRequestClose={() => {
          setAddViewerModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/stream/${stream.uuid}`);
          setSelectedViewer(false);
        }}
        stream={stream}
      />

      <EditViewerModal
        isOpen={editViewerModal}
        title={'Kijker aanpassen'}
        onRequestClose={() => {
          setEditViewerModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/stream/${stream.uuid}`);
          setSelectedViewer(false);
        }}
        viewer={selectedViewer}
      />

      <EmailViewerModal
        isOpen={emailViewerModal}
        title={'Kijker e-mailen'}
        onRequestClose={() => {
          setEmailViewerModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/stream/${stream.uuid}`);
          setSelectedViewer(false);
        }}
        viewer={selectedViewer}
      />

      <UpdateViewerTimesModal
        isOpen={updateViewerTimesModal}
        title={'Kijkrechten aanpassen'}
        onRequestClose={() => {
          setUpdateViewerTimesModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/stream/${stream.uuid}`);
          setSelectedViewer(false);
        }}
        viewer={selectedViewer}
      />

      <PageHeader title={`${stream.company.name} - ${stream.name}`}>
        {loggedInUser.role === 'Admin' && (
          <Button
            inline
            danger
            icon="Trash2"
            title="Verwijder"
            onClick={() => {
              setDeleteStreamModal(true);
            }}
          />
        )}
      </PageHeader>

      <Container>
        <div className="w-full flex md:flex-row flex-col">
          <div className="md:w-1/3 w-full md:mr-5 md:mb-0 mr-0 mb-5">
            <Card>
              <Formik
                initialValues={stream}
                enableReinitialize
                validate={(values) => {
                  const errors = {};

                  if (!values.name) {
                    errors.name = 'Dit veld is verplicht';
                  }
                  if (!values.streamUrl && loggedInUser.role === 'Admin') {
                    errors.streamUrl = 'Dit veld is verplicht';
                  }

                  if (values.contactName && !values.contactEmail) {
                    errors.contactEmail = 'Dit veld is verplicht';
                  }
                  if (values.contactEmail && !values.contactName) {
                    errors.contactName = 'Dit veld is verplicht';
                  }

                  if (
                    values.contactEmail &&
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.contactEmail
                    )
                  ) {
                    errors.contactEmail = 'Ongeldig e-mailadres';
                  }

                  return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    await Axios.put(`api/stream/${stream.id}`, {
                      name: values.name,
                      description: values.description,
                      companyId: values.companyId,
                      streamUrl: values.streamUrl,
                      contactName: values.contactName,
                      contactEmail: values.contactEmail,
                    });

                    mutate(
                      `${process.env.REACT_APP_API_URL}/api/stream/${stream.uuid}`
                    );

                    toast.success(`Stream ${values.name} opgeslagen`);

                    resetForm({ values });
                  } catch (error) {}
                }}
              >
                {({ isSubmitting, dirty, values, setFieldValue }) => (
                  <Form>
                    <FormInputGroup>
                      <FormLabel>Stream naam</FormLabel>
                      <Field type="text" name="name" component={FormInput} />
                      <ErrorMessage name="name" component={FormError} />
                    </FormInputGroup>

                    <FormInputGroup>
                      <FormLabel>Stream omschrijving</FormLabel>
                      <Field
                        type="text"
                        name="description"
                        component={FormInput}
                      />
                    </FormInputGroup>

                    {loggedInUser.role === 'Admin' && (
                      <FormInputGroup>
                        <FormLabel>Stream URL</FormLabel>
                        <Field
                          type="text"
                          name="streamUrl"
                          component={FormInput}
                        />
                        <ErrorMessage name="streamUrl" component={FormError} />
                      </FormInputGroup>
                    )}

                    <FormInputGroup>
                      <FormLabel>Naam contactpersoon</FormLabel>
                      <Field
                        type="text"
                        name="contactName"
                        component={FormInput}
                      />
                      <ErrorMessage name="contactName" component={FormError} />
                    </FormInputGroup>

                    <FormInputGroup last>
                      <FormLabel>E-mailadres contactpersoon</FormLabel>
                      <Field
                        type="text"
                        name="contactEmail"
                        component={FormInput}
                      />
                      <ErrorMessage name="contactEmail" component={FormError} />
                    </FormInputGroup>

                    {dirty && (
                      <Button
                        type="submit"
                        icon="Save"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        title="Opslaan"
                        marginTop
                      />
                    )}
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
          <div className="md:w-2/3 w-full">
            <Card
              header="Kijkers"
              buttons={
                <Button
                  inline
                  icon="Plus"
                  title="Toevoegen"
                  onClick={() => {
                    setAddViewerModal(true);
                  }}
                />
              }
            >
              <Datatable
                columns={tableColumns}
                data={stream.viewer}
                progressPending={streamLoading}
                pointerOnHover
                noDataComponent={`Geen kijkers gevonden voor ${stream.name}`}
                fixedHeader
                onRowClicked={(viewer) => {
                  setSelectedViewer(viewer);
                  setEditViewerModal(true);
                }}
              />
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
}
