import 'plyr/dist/plyr.css';

import React, { useEffect } from 'react';

import Hls from 'hls.js';
import Plyr from 'plyr';

export default function VideoPlyr(props) {
  const { src: plyrSrc, onPlayerEnded } = props;

  useEffect(() => {
    const handlePlyrEnded = () => {
      onPlayerEnded();
    };

    let player;

    const video = document.querySelector('video');

    const plyrOptions = {
      controls: [
        'play-large',
        'play',
        'mute',
        'volume',
        'settings',
        'fullscreen',
      ],
      settings: ['quality'],
      fullscreen: {
        enabled: true,
        iosNative: true,
        fallback: 'force',
      },
    };

    if (Hls.isSupported()) {
      const hls = new Hls();

      const updateQuality = (quality) => {
        hls.levels.forEach((level, index) => {
          if (level.bitrate === quality * 1000000) hls.currentLevel = index;
        });
      };

      hls.loadSource(plyrSrc);

      hls.on(Hls.Events.MANIFEST_PARSED, (_event, _data) => {
        const availableQualities = hls.levels
          .map(({ bitrate }) => {
            // if (bitrate === 3500000) return 1080;
            return bitrate;
          })
          .sort((a, b) => b - a);

        plyrOptions.quality = {
          default: availableQualities[0],
          options: availableQualities,
          forced: true,
          onChange: (e) => updateQuality(e),
        };

        player = new Plyr(video, plyrOptions);

        player.source = {
          poster: '/assets/image/screenshot.png',
        };

        player.on('pause', () => {
          handlePlyrEnded();
        });
      });

      hls.attachMedia(video);
    } else {
      player = new Plyr(video, plyrOptions);

      player.source = {
        type: 'video',
        poster: '/assets/image/screenshot.png',
        sources: [
          {
            src: plyrSrc,
            provider: 'html5',
          },
        ],
      };

      player.on('pause', () => {
        handlePlyrEnded();
      });
    }
  }, [onPlayerEnded, plyrSrc]);

  return (
    <>
      <video
        poster="/assets/image/screenshot.png"
        controls
        // crossOrigin
        playsInline
        autoPlay
        className={'hidden'}
        style={{
          width: '100%',
          '--plyr-color-main': props.color ? props.color : '#AF9358',
          '--plyr-badge-border-radius': '0.25rem',
          '----plyr-control-radius': '0.25rem',
        }}
      ></video>
    </>
  );
}
