import { useCamBox } from '../../data';

import { Field, Form, Formik } from 'formik';

import Moment from 'react-moment';

import Button from '../../components/ui/Button';

import Card from '../../components/ui/Card';
import Container from '../../components/ui/Container';

import CamBoxOnMapModal from '../../components/modal/CamBoxOnMapModal';
// import PrompterCamBoxModal from '../../components/modal/PrompterCamBoxModal';
import StatisticsCamBoxModal from '../../components/modal/StatisticsCamBoxModal';
import SettingsCamBoxModal from '../../components/modal/SettingsCamBoxModal';
import EditCamBoxModal from '../../components/modal/EditCamBoxModal';
import ViscaCamBoxModal from '../../components/modal/ViscaCamBoxModal';
// import DeleteModal from '../../components/modal/DeleteModal';

import PageError from '../../components/ui/PageError';
import PageHeader from '../../components/dashboard/PageHeader';
import PageLoader from '../../components/ui/PageLoader';
import Block from '../../components/ui/Block';

import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';

import { mutate } from 'swr';
import toast from 'react-hot-toast';

import { useRouteMatch } from 'react-router-dom';
import React, { useState, useContext, useCallback, useEffect } from 'react';

// import VideoPlyr from '../../components/plyr';

import { useDebouncyFn } from 'use-debouncy';
import { RgbaColorPicker } from 'react-colorful';

import { SocketContext } from '../../context/socket';

import { useLoggedInUser } from '../../data';

export default function CamBoxPage() {
  const { params } = useRouteMatch();

  const { loggedInUser, loggedInUserLoading } = useLoggedInUser();

  const socket = useContext(SocketContext);

  const [camBoxOnMapModal, setCamBoxOnMapModal] = useState(false);
  // const [prompterCamBoxModal, setPrompterCamBoxModal] = useState(false);
  const [settingsCamBoxModal, setSettingsCamBoxModal] = useState(false);
  const [statisticsCamBoxModal, setStatisticsCamBoxModal] = useState(false);
  const [editCamBoxModal, setEditCamBoxModal] = useState(false);
  const [viscaCamBoxModal, setViscaCamBoxModal] = useState(false);
  // const [deleteModal, setDeleteModal] = useState(false);

  const { cambox, camboxLoading, camboxError } = useCamBox(params.cambox);

  const [socketAuthorized, setSocketAuthorized] = useState(false);

  const [ledRingValue, setLedRingValue] = useState(cambox?.ledRing || false);
  const [ledStripValue, setLedStripValue] = useState(cambox?.ledStrip || false);

  const [displayValue, setDisplayValue] = useState();

  const handleAuthorized = useCallback((status) => {
    setSocketAuthorized(status);
  }, []);

  const handleDisconnect = useCallback(() => {
    setSocketAuthorized(false);
  }, []);

  const handleSocketMessage = useCallback((message) => {
    const { topic, payload } = message;

    switch (topic) {
      case 'led':
        if (payload.led === 'ring') setLedRingValue(payload.color);
        if (payload.led === 'strip') setLedStripValue(payload.color);
        break;

      case 'display':
        setDisplayValue(payload.output);

        break;

      case 'graphics':
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.connect();

      socket.on('connect', () => {
        socket.emit('requestAccessToCamBox', params.cambox);
      });

      socket.on('authorized', handleAuthorized);
      socket.on('disconnected', handleDisconnect);

      socket.on(params.cambox, handleSocketMessage);

      return () => {
        socket.off('authorized', handleAuthorized);
        socket.off('disconnected', handleDisconnect);

        socket.off(params.cambox, handleSocketMessage);

        socket.disconnect();
      };
    }
  }, [
    socket,
    handleAuthorized,
    handleDisconnect,
    params.cambox,
    handleSocketMessage,
  ]);

  const handleViscaPTZ = useCallback((command) => {
    socket.emit('cambox', {
      camBoxUUID: params.cambox,
      topic: 'viscaPTZ',
      payload: { command },
    });
  }, []);

  const handleViscaCamera = useCallback((command) => {
    socket.emit('cambox', {
      camBoxUUID: params.cambox,
      topic: 'viscaCamera',
      payload: { command },
    });
  }, []);

  const handleDisplay = useCallback((output) => {
    setDisplayValue(output);

    socket.emit('cambox', {
      camBoxUUID: params.cambox,
      topic: 'display',
      payload: { output },
    });
  }, []);

  const handleLinak = useCallback((direction) => {
    socket.emit('cambox', {
      camBoxUUID: params.cambox,
      topic: 'linak',
      payload: { direction },
    });
  }, []);

  const handleSetting = useCallback((setting, command) => {
    socket.emit('cambox', {
      camBoxUUID: params.cambox,
      topic: 'setting',
      payload: { setting, command },
    });
  }, []);

  const handleLedChange = useDebouncyFn(
    (led, color) => handleLed(led, color),
    200
  );

  const maxLedBrightness = 100;

  const alphaToBrightness = (alpha) => {
    return Math.round(((alpha - 0) * (maxLedBrightness - 0)) / (1 - 0) + 0);
  };

  const handleLed = useCallback((led, color) => {
    socket.emit('cambox', {
      camBoxUUID: params.cambox,
      topic: 'led',
      rawData: color,
      payload: {
        led,
        color,
        brightness: alphaToBrightness(color.a) || 0,
      },
    });
  }, []);

  const ColorPreset = (props) => {
    return (
      <div
        className="flex items-center justify-center p-2 text-white uppercase bg-black rounded outline-none cursor-pointer focus:outline-none hover:opacity-90"
        onClick={() => {
          handleLedChange(props.led, props.color);

          if (props.led === 'ring') setLedRingValue(props.color);
          if (props.led === 'strip') setLedStripValue(props.color);
        }}
      >
        {props.label}
      </div>
    );
  };

  if (camboxError) return <PageError />;
  if (camboxLoading || loggedInUserLoading || !socketAuthorized)
    return <PageLoader />;

  return (
    <>
      <CamBoxOnMapModal
        isOpen={camBoxOnMapModal}
        title={`Current location of ${cambox.name}`}
        onRequestClose={() => {
          setCamBoxOnMapModal(false);
        }}
        cambox={cambox}
      />

      {/* <PrompterCamBoxModal
        isOpen={prompterCamBoxModal}
        title={'Prompter'}
        onRequestClose={() => {
          setPrompterCamBoxModal(false);
        }}
        cambox={cambox}
        // handlePrompter={handlePrompter}
      /> */}

      <StatisticsCamBoxModal
        isOpen={statisticsCamBoxModal}
        title={'Statistics'}
        onRequestClose={() => {
          setStatisticsCamBoxModal(false);
        }}
        cambox={cambox}
      />

      <SettingsCamBoxModal
        isOpen={settingsCamBoxModal}
        title={'Settings'}
        onRequestClose={() => {
          setSettingsCamBoxModal(false);
        }}
        handleSetting={handleSetting}
        handleLinak={handleLinak}
      />

      {loggedInUser?.role === 'Admin' && (
        <EditCamBoxModal
          isOpen={editCamBoxModal}
          title={'Edit Cambox'}
          onRequestClose={() => {
            setEditCamBoxModal(false);
            mutate(
              `${process.env.REACT_APP_API_URL}/api/cambox/${cambox.uuid}`
            );
          }}
          cambox={cambox}
        />
      )}

      <ViscaCamBoxModal
        isOpen={viscaCamBoxModal}
        title={'Visca control'}
        onRequestClose={() => {
          setViscaCamBoxModal(false);
        }}
        handleViscaCamera={handleViscaCamera}
      />

      {/* <DeleteModal
        isOpen={deleteModal}
        title={'Delete Cambox'}
        onRequestClose={() => {
          setDeleteModal(false);
        }}
        onSubmit={async () => {
          try {
            await Axios.delete(`api/cambox/${cambox.uuid}`);

            toast.success(`Cambox ${cambox.name} deleted`);

            setDeleteModal(false);
            history.push(`/dashboard/cambox`);
          } catch (error) {}
        }}
      /> */}

      <PageHeader title={`Cambox: ${cambox.name}`}>
        {/* <Button
          inline
          icon="AlignLeft"
          title="Prompter"
          onClick={() => {
            setPrompterCamBoxModal(true);
          }}
          className="mr-2"
        /> */}

        {cambox.onlineStatus && (
          <>
            <Button
              inline
              icon="BarChart"
              title="Statistics"
              onClick={() => {
                setStatisticsCamBoxModal(true);
              }}
              className="mr-2"
            />

            <Button
              inline
              icon="Settings"
              title="Settings"
              onClick={() => {
                setSettingsCamBoxModal(true);
              }}
              className="mr-2"
            />
          </>
        )}

        {loggedInUser?.role === 'Admin' && (
          <Button
            inline
            icon="Edit"
            title="Edit"
            onClick={() => {
              setEditCamBoxModal(true);
            }}
            className="mr-2"
          />
        )}

        {/* <Button
          inline
          danger
          icon="Trash2"
          title="Delete"
          onClick={() => {
            setDeleteModal(true);
          }}
        /> */}
      </PageHeader>

      <Block className="text-black bg-white">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            {cambox.onlineStatus && (
              <>
                <div className="w-4 h-4 mr-2 bg-green-600 rounded-full"></div>

                <h4 className="m-0">
                  Online
                  <span className="hidden sm:inline">
                    {' '}
                    - since{' '}
                    <Moment
                      fromNowDuring={3600}
                      locale="en"
                      format="MMMM DD YYYY[,] HH:mm"
                    >
                      {cambox.onlineSince}
                    </Moment>
                  </span>
                </h4>
              </>
            )}

            {!cambox.onlineStatus && (
              <>
                <div className="w-4 h-4 mr-2 bg-red-600 rounded-full"></div>

                <h4 className="m-0">
                  Offline
                  <span className="hidden sm:inline">
                    {' '}
                    - since{' '}
                    <Moment
                      fromNowDuring={3600}
                      locale="en"
                      format="MMMM DD YYYY[,] HH:mm"
                    >
                      {cambox.offlineSince}
                    </Moment>
                  </span>
                </h4>
              </>
            )}
          </div>

          {cambox.onlineStatus &&
            cambox.gpsLocation?.lat &&
            cambox.gpsLocation?.lon && (
              <Button
                inline
                icon="MapPin"
                title="Location"
                onClick={() => {
                  setCamBoxOnMapModal(true);
                }}
                className="mr-2"
              />
            )}
        </div>
      </Block>

      <Container>
        <div className="flex flex-col w-full md:flex-row">
          <div className="w-full mb-5 mr-0 md:w-1/2 md:mr-5 md:mb-0">
            {/* <div className="mb-5 overflow-hidden rounded">
              <VideoPlyr
                src={
                  'https://2097.streamingmvp.com/recordings/recordings/480-p-50-wmt-13917-1/live.m3u8'
                }
              />
            </div> 

            <div className="mb-5 bg-black rounded aspect-w-16 aspect-h-9"></div> */}

            <div className="flex flex-col w-full mb-0 md:flex-row md:mb-5">
              <Card
                header="Camera"
                inline
                className="relative w-full mb-5 mr-0 md:w-1/2 md:mr-5 md:mb-0"
                disabled={!cambox.onlineStatus}
              >
                <div className="flex flex-row">
                  {/* <div className="flex flex-col w-4">
                    <div className="h-1/2">1</div>
                    <div className="h-1/2">2</div>
                  </div> */}

                  <div className="w-full">
                    {/* <div className="flex justify-center mb-2 align-middle">
                      <CombiButton
                        iconLeft="ArrowUp"
                        iconRight="ArrowDown"
                        onClickLeft={() =>
                          handleViscaPTZ(
                            '0x44, 0x2a, 0x01, 0x00, 0x64, 0x7f, 0x00, 0x00, 0x1b'
                          )
                        }
                        onClickRight={() =>
                          handleViscaPTZ(
                            '0x44, 0x2a, 0x01, 0x00, 0x64, 0x7f, 0x00, 0x00, 0x1b'
                          )
                        }
                        title="Joe"
                      />
                    </div> */}

                    <div className="flex justify-center mb-2 align-middle">
                      <Button
                        icon="ArrowUp"
                        onClick={() =>
                          handleViscaPTZ(
                            '0x44, 0x2a, 0x01, 0x00, 0x64, 0x7f, 0x00, 0x00, 0x1b'
                          )
                        }
                      />
                    </div>
                    <div className="flex justify-center mb-2 align-middle">
                      <Button
                        icon="ArrowLeft"
                        className="mr-1"
                        onClick={() =>
                          handleViscaPTZ(
                            '0x44, 0x2a, 0x01, 0x00, 0x7f, 0x64, 0x00, 0x00, 0x1b'
                          )
                        }
                      />
                      <Button
                        icon="ArrowRight"
                        className="ml-1"
                        onClick={() =>
                          handleViscaPTZ(
                            '0x44, 0x2a, 0x01, 0x00, 0x7f, 0x9a, 0x00, 0x00, 0xe5'
                          )
                        }
                      />
                    </div>
                    <div className="flex justify-center mb-5 align-middle">
                      <Button
                        icon="ArrowDown"
                        onClick={() =>
                          handleViscaPTZ(
                            '0x44, 0x2a, 0x01, 0x00, 0x9a, 0x7f, 0x00, 0x00, 0xe5'
                          )
                        }
                      />
                    </div>
                    {/* Head
                <input
                  type="text"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      console.log(event.target.value);
                      handleViscaPTZ(event.target.value);
                    }
                  }}
                />
                <br></br>
                Camera:
                <input
                  type="text"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      console.log(event.target.value);
                      handleViscaCamera(event.target.value);
                    }
                  }}
                /> */}
                    {/* <div className="flex justify-center mb-5 align-middle">
                      <Button
                        icon="Home"
                        onClick={() =>
                          handleViscaPTZ(
                            '0x44, 0x2A, 0x01, 0x31, 0x00, 0x00, 0x00, 0x01, 0xCC'
                          )
                        }
                      />
                    </div> */}
                    <div className="flex justify-center mb-5 align-middle">
                      <Button
                        icon="ZoomIn"
                        className="mr-1"
                        onClick={() => {
                          handleViscaCamera(
                            '0x81, 0x01, 0x04, 0x06, 0x20, 0xFF'
                          );
                        }}
                      />
                      <Button
                        icon="ZoomOut"
                        className="ml-1"
                        onClick={() => {
                          handleViscaCamera(
                            '0x81, 0x01, 0x04, 0x06, 0x30, 0xFF'
                          );
                        }}
                      />
                    </div>
                    <div className="flex justify-center align-middle">
                      <Button
                        icon="Video"
                        title="Settings"
                        onClick={() => {
                          setViscaCamBoxModal(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Card>

              <Card
                header="Display"
                inline
                className="w-full ml-0 mr-0 md:w-1/2 md:ml-0"
                disabled={!cambox.onlineStatus}
              >
                <Button
                  title="Graphics"
                  className={`mb-2 ${
                    displayValue === 'Graphics' ? 'bg-br-gold' : ''
                  }`}
                  onClick={() => handleDisplay('Graphics')}
                />
                {/* <Button
                  title="Prompter"
                  className={`mb-2 ${
                    displayValue === 'Prompter' ? 'bg-br-gold' : ''
                  }`}
                  onClick={() => handleDisplay('Prompter')}
                /> */}
                <Button
                  title="Camera"
                  className={`mb-2 ${
                    displayValue === 'Camera' ? 'bg-br-gold' : ''
                  }`}
                  onClick={() => handleDisplay('Camera')}
                />
                <Button
                  title="Return feed"
                  className={`mb-2 ${
                    displayValue === 'Return' ? 'bg-br-gold' : ''
                  }`}
                  onClick={() => handleDisplay('Return')}
                />
                <Button
                  title="PIP"
                  className={`${displayValue === 'PIP' ? 'bg-br-gold' : ''}`}
                  onClick={() => handleDisplay('PIP')}
                />
              </Card>
            </div>

            {cambox.returnbox && (
              <div className="flex flex-col w-full mb-0 md:flex-row md:mb-5">
                <Card
                  header={`Returnbox: ${cambox.returnbox.name}`}
                  inline
                  className="w-full ml-0 mr-0 "
                  disabled={!cambox.onlineStatus}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      {cambox.returnbox.onlineStatus && (
                        <>
                          <div className="w-4 h-4 mr-2 bg-green-600 rounded-full"></div>

                          <h4 className="m-0">Online</h4>
                        </>
                      )}
                      {!cambox.returnbox.onlineStatus && (
                        <>
                          <div className="w-4 h-4 mr-2 bg-red-600 rounded-full"></div>

                          <h4 className="m-0">Offline</h4>
                        </>
                      )}
                    </div>
                  </div>

                  {cambox.returnbox.onlineStatus && (
                    <div className="mt-5 overflow-hidden bg-black rounded aspect-w-16 aspect-h-9">
                      <img
                        src={`https://webrtc.bcrflex.com/Returnbox/previews/${
                          cambox.returnbox.uuid
                        }-video.png?${global.Date.now()}`}
                        alt=""
                        className="object-contain"
                      />
                    </div>
                  )}
                </Card>
              </div>
            )}
          </div>

          <div className="flex flex-col flex-wrap w-full md:w-1/2">
            <div className="flex flex-col w-full mb-0 md:flex-row md:mb-5">
              <Card
                header="LED ring"
                inline
                className="w-full mb-5 mr-0 md:mr-5 md:mb-0"
                disabled={!cambox.onlineStatus}
              >
                <RgbaColorPicker
                  color={ledRingValue}
                  onChange={(color) => {
                    handleLedChange('ring', color);
                  }}
                />
                <div className="flex justify-between w-full mt-2">
                  <ColorPreset
                    led="ring"
                    color={{ r: 255, g: 187, b: 120, a: 1 }}
                    label="3200 K"
                  />
                  <ColorPreset
                    led="ring"
                    color={{ r: 255, g: 209, b: 163, a: 1 }}
                    label="4000 K"
                  />
                  <ColorPreset
                    led="ring"
                    color={{ r: 255, g: 249, b: 253, a: 1 }}
                    label="6500 K"
                  />

                  <ColorPreset
                    led="ring"
                    color={{ r: 0, g: 0, b: 0, a: 0 }}
                    label="Off"
                  />
                </div>
              </Card>

              <Card
                header="LED strip"
                inline
                className="w-full mb-5 ml-0 mr-0 md:mb-0 md:ml-0"
                disabled={!cambox.onlineStatus}
              >
                <RgbaColorPicker
                  color={ledStripValue}
                  onChange={(color) => {
                    handleLedChange('strip', color);
                  }}
                />
                <div className="flex justify-between w-full mt-2">
                  <ColorPreset
                    led="strip"
                    color={{ r: 255, g: 0, b: 0, a: 1 }}
                    label="Red"
                  />
                  <ColorPreset
                    led="strip"
                    color={{ r: 0, g: 255, b: 0, a: 1 }}
                    label="Green"
                  />
                  <ColorPreset
                    led="strip"
                    color={{ r: 255, g: 255, b: 255, a: 1 }}
                    label="White"
                  />
                  <ColorPreset
                    led="strip"
                    color={{ r: 0, g: 0, b: 0, a: 0 }}
                    label="Off"
                  />
                </div>
              </Card>
            </div>

            <Card header="Graphics" inline className="w-full">
              <Formik
                initialValues={cambox}
                enableReinitialize
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    await socket.emit('cambox', {
                      camBoxUUID: params.cambox,
                      topic: 'graphics',

                      payload: {
                        graphicsName: values.graphicsName,
                        graphicsMessage: values.graphicsMessage,
                      },
                    });

                    toast.success(`Graphics saved`);

                    resetForm({ values });
                  } catch (error) {}
                }}
              >
                {({ isSubmitting, dirty, values, setFieldValue }) => (
                  <Form>
                    <FormInputGroup>
                      <FormLabel>Name</FormLabel>
                      <Field
                        type="text"
                        name="graphicsName"
                        component={FormInput}
                      />
                    </FormInputGroup>

                    <FormInputGroup last>
                      <FormLabel>Message</FormLabel>
                      <Field
                        type="text"
                        name="graphicsMessage"
                        component={FormInput}
                      />
                    </FormInputGroup>

                    {dirty && (
                      <Button
                        type="submit"
                        icon="Save"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        title="Save"
                        marginTop
                      />
                    )}
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
}
