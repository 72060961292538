import Button from '../../components/ui/Button';
import Modal from './index';

export default function Component(props) {
  return (
    <Modal {...props}>
      <div className="rounded p-5 mb-5 bg-gray-200 text-xl">Are you sure?</div>
      <Button type="submit" title="Delete" autoFocus onClick={props.onSubmit} />
    </Modal>
  );
}
