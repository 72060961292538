import DataTable from 'react-data-table-component';
import { Loader } from 'react-feather';

export default function Datatable(props) {
  return (
    <div className="w-full">
      <DataTable
        highlightOnHover={true}
        paginationComponentOptions={{
          rowsPerPageText: 'Aantal per pagina:',
          rangeSeparatorText: 'van',
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: 'Alle',
        }}
        pagination={props.data.length > 10 ? true : false}
        customStyles={{
          header: {
            style: {
              fontSize: '16px',
            },
          },
          headCells: {
            style: {
              fontSize: '16px',
            },
          },
          contextMenu: {
            style: {
              fontSize: '16px',
            },
          },
          rows: {
            style: {
              fontSize: '16px',
            },
          },
          pagination: {
            style: {
              fontSize: '16px',
            },
          },
        }}
        noDataComponent="Geen resultaten om weer te geven"
        progressComponent={<Loader size={48} className="animate-spin" />}
        {...props}
      />
    </div>
  );
}
