import Block from '../../components/ui/Block';

export default function Header() {
  return (
    <>
      <Block
        className="md:mb-5 md:flex-row flex-col md:text-left items-center text-center"
        dark
      >
        <img
          src="/assets/image/powered-by-bcr-alt.svg"
          alt=""
          className="h-4"
        />

        <div className="md:mt-0 mt-2">
          <a href="tel:+31358886825">+31 35 888 6825</a>
          <span> ― </span>
          <a href="mailto:info@bcrflex.com">info@bcrflex.com</a>
        </div>
      </Block>
    </>
  );
}
