import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import AuthService from '../src/services/auth';
import Dashboard from './pages/dashboard';
import LivePage from './pages/live';
import LoginPage from './pages/dashboard/LoginPage.js';
import NotFoundPage from './pages/NotFoundPage.js';
import { Toaster } from 'react-hot-toast';

import { SWRConfig } from 'swr';

export default function App() {
  return (
    <>
      <Toaster
        toastOptions={{
          duration: 2500,

          style: {
            margin: '2.5rem',
            borderRadius: '0.25rem',
            padding: '1.25rem',
          },
        }}
      />

      {process.env.NODE_ENV === 'development' && (
        <div className="bg-red-400 text-center py-4 text-white font-bold">
          DEVELOPMENT
        </div>
      )}

      <SWRConfig
        value={{
          shouldRetryOnError: false,
        }}
      >
        <Router>
          <Switch>
            <Route path="/live/:stream/:viewer" component={LivePage} />

            <Route
              path="/"
              exact
              render={() => {
                return <Redirect to="/dashboard" />;
              }}
            />

            <Route
              path="/login/:token?"
              exact
              render={() => {
                if (AuthService.isAuthenticated())
                  return <Redirect to="/dashboard" />;
                return <LoginPage />;
              }}
            />

            <Route
              path="/dashboard"
              render={() => {
                if (!AuthService.isAuthenticated())
                  return <Redirect to="/login" />;
                return <Dashboard />;
              }}
            />

            <Route
              path="/logout"
              exact
              render={() => {
                AuthService.logOut();
                return <Redirect to="/login" />;
              }}
            />

            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </SWRConfig>
    </>
  );
}
