import { ErrorMessage, Field, Form, Formik } from 'formik';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';
import Modal from './index';
import toast from 'react-hot-toast';

export default function Component(props) {
  return (
    <Modal {...props}>
      <Formik
        initialValues={{
          name: '',
          description: '',
          companyId: '',
          streamUrl: '',
        }}
        validate={(values) => {
          const errors = {};

          if (!values.name) {
            errors.name = 'Dit veld is verplicht';
          }

          if (!values.streamUrl) {
            errors.streamUrl = 'Dit veld is verplicht';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await Axios.post('api/stream', {
              name: values.name,
              description: values.description,
              streamUrl: values.streamUrl,
              companyId: props.company.id,
            });

            toast.success(`Stream ${values.name} toegevoegd`);

            props.onRequestClose();
          } catch (error) {}
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormInputGroup>
              <FormLabel>Naam</FormLabel>
              <Field type="text" name="name" autoFocus component={FormInput} />
              <ErrorMessage name="name" component={FormError} />
            </FormInputGroup>

            <FormInputGroup>
              <FormLabel>Omschrijving</FormLabel>
              <Field type="text" name="description" component={FormInput} />
            </FormInputGroup>

            <FormInputGroup>
              <FormLabel>Stream URL</FormLabel>
              <Field type="text" name="streamUrl" component={FormInput} />
              <ErrorMessage name="streamUrl" component={FormError} />
            </FormInputGroup>

            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              title="Stream toevoegen"
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
