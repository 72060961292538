import { NavLink } from 'react-router-dom';

export default function MenuLink(props) {
  return (
    <li className={`md:ml-6 md:mt-0 mt-1 text-xl leading-14`}>
      <NavLink
        to={props.href}
        exact
        className="text-black text-opacity-50"
        activeClassName="text-opacity-100"
        {...props}
      >
        {props.title}
      </NavLink>
    </li>
  );
}
