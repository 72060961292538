import { X } from 'react-feather';

import Modal from 'react-modal';

export default function Component(props) {
  const modalStyles = {
    overlay: {
      backgroundColor: 'rgb(0 0 0 / 75%)',
      backdropFilter: 'blur(4px)',
      WebkitBackdropFilter: 'blur(4px)',
      zIndex: '100',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      borderRadius: '0.25rem',
      outline: 'none',
      padding: '1.25rem',
      width: props.large ? '1160px' : '600px',
      maxWidth: 'calc(100vw - 2.5rem)',
      maxHeight: 'calc(100vh - 5rem)',
    },
  };

  Modal.setAppElement('#root');

  return (
    <Modal style={modalStyles} closeTimeoutMS={150} {...props}>
      <div className="bg-br-gold -m-5 p-5 mb-5 text-white flex justify-between">
        <h2 className="m-0">{props.title}</h2>
        <div className="cursor-pointer">
          <X strokeWidth="2" size={30} onClick={props.onRequestClose} />
        </div>
      </div>

      {props.children}
    </Modal>
  );
}
