import { ErrorMessage, Field, Form, Formik } from 'formik';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';
import { useState } from 'react';

export default function LoginForm() {
  const [succes, setSuccess] = useState(false);

  return (
    <>
      <div className="md:w-1/2 w-3/4 max-w-lg">
        <div className="px-24 py-5 mb-10">
          <img src="/assets/image/logo-alt.svg" alt="" className="" />
        </div>

        <Card>
          <h2 className="text-center">Inloggen bij BCRFLEX</h2>

          {!succes && (
            <Formik
              initialValues={{ email: '' }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Dit veld is verplicht';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Ongeldig e-mailadres';
                }
                return errors;
              }}
              onSubmit={async (values) => {
                try {
                  await Axios.post('auth/login', { email: values.email });
                  setSuccess(true);
                } catch (error) {}
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormInputGroup>
                    <FormLabel>E-mailadres</FormLabel>
                    <Field
                      // type="email"
                      name="email"
                      autoFocus
                      component={FormInput}
                    />
                    <ErrorMessage name="email" component={FormError} />
                  </FormInputGroup>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    title="Inloggen"
                  />
                </Form>
              )}
            </Formik>
          )}

          {succes && (
            <div className="text-center">
              Er is een e-mail verzonden met instructies
            </div>
          )}
        </Card>
      </div>
    </>
  );
}
