import Modal from './index';

export default function Component(props) {
  return (
    <Modal {...props}>
      <>
        <div className="flex flex-wrap align-middle justify-center -mb-5">
          {props.returnbox && (
            <>
              <video src="" />
            </>
          )}
        </div>
      </>
    </Modal>
  );
}
