export default function Container(props) {
  return (
    <div
      className={`${
        props.fullWidth ? 'w-full' : 'container'
      } mx-auto px-5 mb-5 flex flex-wrap justify-between rounded ${
        props.className ? props.className : ''
      }`}
    >
      {props.children}
    </div>
  );
}
