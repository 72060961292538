import Button from '../../components/ui/Button';
import Modal from './index';

export default function Component(props) {
  const { handleViscaCamera } = props;

  const buttonGroups = [
    {
      title: 'White balance',
      buttons: [
        {
          name: 'Auto',
          command: '0x81, 0x01, 0x04, 0x35, 0x00, 0xFF',
        },
        { name: 'Manual', command: '0x81, 0x01, 0x04, 0x35, 0x05, 0xFF' },
        {
          name: 'Red Gain Up',
          icon: 'ArrowUp',
          command: '0x81, 0x01, 0x04, 0x03, 0x02, 0xFF',
        },
        {
          name: 'Red Gain Down',
          command: '0x81, 0x01, 0x04, 0x03, 0x03, 0xFF',
        },
        {
          name: 'Blue Gain Up',
          command: '0x81, 0x01, 0x04, 0x04, 0x02, 0xFF',
        },
        {
          name: 'Blue Gain Down',
          command: '0x81, 0x01, 0x04, 0x04, 0x03, 0xFF',
        },
      ],
    },

    {
      title: 'Exsposure',
      buttons: [
        { name: 'Full auto', command: '0x81, 0x01, 0x04, 0x39, 0x00, 0xFF' },
        { name: 'Manual', command: '0x81, 0x01, 0x04, 0x39, 0x03, 0xFF' },
        {
          name: 'Gain up',
          command: '0x81, 0x01, 0x04, 0x0C, 0x02, 0xFF',
        },
        {
          name: 'Gain down',
          command: '0x81, 0x01, 0x04, 0x0C, 0x03, 0xFF',
        },
      ],
    },

    {
      title: 'Sharpness',
      buttons: [
        {
          name: 'Up',
          command: '0x81, 0x01, 0x04, 0x02, 0x02, 0xFF',
        },
        {
          name: 'Down',
          command: '0x81, 0x01, 0x04, 0x02, 0x03, 0xFF',
        },
      ],
    },
    {
      title: 'Brightness',
      buttons: [
        {
          name: 'Up',
          command: '0x81, 0x01, 0x04, 0x0e, 0x02, 0xFF',
        },
        {
          name: 'Down',
          command: '0x81, 0x01, 0x04, 0x0e, 0x03, 0xFF',
        },
      ],
    },
  ];

  return (
    <Modal {...props}>
      <>
        <div className="flex flex-wrap align-middle justify-center -mb-5">
          {buttonGroups.map((group, groupIndex) => (
            <div
              className={`w-1/2 mb-5 ${groupIndex % 2 ? 'pl-2' : 'pr-2'}`}
              index={groupIndex}
            >
              <h2 className="m-0 mb-2">{group.title}</h2>

              {group.buttons?.map((button, buttonIndex) => (
                <Button
                  title={button.name}
                  // icon={button.icon}
                  className={`mb-2`}
                  onClick={() => {
                    handleViscaCamera(button.command);
                  }}
                  index={buttonIndex}
                />
              ))}
            </div>
          ))}

          <div className="w-1/2 mb-5">
            <h2 className="m-0 mb-2">Menu</h2>

            <Button
              title="Open"
              className={`mb-2`}
              onClick={() =>
                handleViscaCamera('0x81, 0x01, 0x06, 0x06, 0x02, 0xFF')
              }
            />

            <div className="flex align-middle justify-center mb-2">
              <Button
                icon="ArrowUp"
                className="mr-0"
                onClick={() =>
                  handleViscaCamera('0x81, 0x01, 0x06, 0x06, 0x11, 0xFF')
                }
              />
            </div>

            <div className="flex align-middle justify-center mb-2">
              <Button
                icon="ArrowLeft"
                className="mr-1"
                onClick={() =>
                  handleViscaCamera('0x81, 0x01, 0x06, 0x06, 0x14, 0xFF')
                }
              />

              <Button
                title="Ok"
                className={`mr-1`}
                onClick={() =>
                  handleViscaCamera('0x81, 0x01, 0x06, 0x06, 0x02, 0xFF')
                }
              />

              <Button
                icon="ArrowRight"
                className="ml-1"
                onClick={() =>
                  handleViscaCamera('0x81, 0x01, 0x06, 0x06, 0x18, 0xFF')
                }
              />
            </div>

            <div className="flex align-middle justify-center mb-2">
              <Button
                icon="ArrowDown"
                onClick={() =>
                  handleViscaCamera('0x81, 0x01, 0x06, 0x06, 0x12, 0xFF')
                }
              />
            </div>

            <Button
              title="Close"
              className={`mb-2`}
              onClick={() =>
                handleViscaCamera('0x81, 0x01, 0x06, 0x06, 0x03, 0xFF')
              }
            />
          </div>
        </div>
      </>
    </Modal>
  );
}
