import { ErrorMessage, Field, Form, Formik } from 'formik';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';
import FormSelect from '../../components/ui/FormSelect';
import Modal from './index';
import toast from 'react-hot-toast';

export default function Component(props) {
  return (
    <Modal {...props}>
      <Formik
        initialValues={{ name: '', allowAlways: '', password: '' }}
        validate={(values) => {
          const errors = {};

          if (!values.name) {
            errors.name = 'Dit veld is verplicht';
          }
          if (!values.allowAlways) {
            errors.allowAlways = 'Dit veld is verplicht';
          }

          return errors;
        }}
        onSubmit={async (values) => {
          try {
            await Axios.post('api/viewer', {
              name: values.name,
              streamId: props.stream.id,
              companyId: props.stream.company.id,
              allowAlways: values.allowAlways,
              password: values.password,
            });

            toast.success(`Kijker ${values.name} toegevoegd`);

            props.onRequestClose();
          } catch (error) {}
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <FormInputGroup>
              <FormLabel>Naam</FormLabel>
              <Field type="text" name="name" autoFocus component={FormInput} />
              <ErrorMessage name="name" component={FormError} />
            </FormInputGroup>

            <FormInputGroup>
              <FormLabel>
                Mag {values.name ? values.name : 'de kijker'} altijd meekijken?
              </FormLabel>

              <Field as="select" name="allowAlways" component={FormSelect}>
                <option value="" disabled selected>
                  ...
                </option>

                <option value="true">Ja</option>
                <option value="false">Nee</option>
              </Field>

              <ErrorMessage name="allowAlways" component={FormError} />
            </FormInputGroup>

            <FormInputGroup>
              <FormLabel>Wachtwoord (optioneel)</FormLabel>

              <Field type="password" name="password" component={FormInput} />
              <ErrorMessage name="password" component={FormError} />
            </FormInputGroup>

            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              title="Kijker toevoegen"
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
