import { useMemo } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';

import Moment from 'react-moment';

import Modal from './index';

export default function Component(props) {
  const location = useMemo(
    () => [props.cambox.gpsLocation?.lat, props.cambox.gpsLocation?.lon],
    [props]
  );

  const zoomLevel = 11;

  function Map() {
    const map = useMap();
    map.setView(location);
    return null;
  }

  return (
    <Modal {...props}>
      {!location[0] && !location[1] && <>Location unknown</>}

      {props.cambox && location[0] && location[1] && (
        <>
          <MapContainer
            className="h-96 w-full"
            center={location}
            zoom={zoomLevel}
            scrollWheelZoom={true}
          >
            <Map />
            <Marker position={location}></Marker>

            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </MapContainer>

          <div className="mt-2">
            Updated:{' '}
            <Moment fromNow locale="en">
              {props.cambox.gpsLocationUpdatedAt}
            </Moment>
          </div>
        </>
      )}
    </Modal>
  );
}
