import 'moment/locale/nl';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Trash2 } from 'react-feather';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import Modal from './index';
import moment from 'moment';

import toast from 'react-hot-toast';

const now = new Date();

export default function Component(props) {
  moment.locale('nl');

  const localizer = momentLocalizer(moment);

  const [viewerRights, setViewerRights] = useState([]);

  useEffect(() => {
    if (props.viewer?.viewerRights) {
      let times = props.viewer.viewerRights;

      times.forEach((timeSlot) => {
        timeSlot.startDateTime = new Date(timeSlot.startDateTime);
        timeSlot.endDateTime = new Date(timeSlot.endDateTime);
      });

      setViewerRights(times);
    }
  }, [props.viewer?.viewerRights]);

  const handleSelectSlot = (selectedSlot) => {
    let newViewerRights = viewerRights;

    if (selectedSlot.slots.length === 1) {
      //  REMOVE ALL EVENTS ON THIS DAY
      newViewerRights = newViewerRights.filter(
        (item) => !moment(item.startDateTime).isSame(selectedSlot.start, 'day')
      );
    } else {
      // REMOVE THE WHOLE DAY EVENT
      newViewerRights = newViewerRights.filter(
        (item) =>
          !(
            moment(item.startDateTime).isSame(selectedSlot.start, 'day') &&
            moment(item.startDateTime).hour() === 0 &&
            moment(item.endDateTime).hour() === 0
          )
      );
    }

    let overLappingEvents = newViewerRights.filter((item) => {
      const itemStart = moment(item.startDateTime);
      const itemEnd = moment(item.endDateTime);

      const selectedSlotStart = moment(selectedSlot.start);
      const selectedSlotEnd = moment(selectedSlot.end);

      // SELECTION ALREADY EXISTS
      if (
        itemStart.isSame(selectedSlotStart) &&
        itemEnd.isSame(selectedSlotEnd)
      ) {
        return true;
      }
      // SELECTION BETWEEN EXISTING ITEM
      if (
        (itemStart.isBetween(selectedSlotStart, selectedSlotEnd) ||
          selectedSlotStart.isSame(itemStart)) &&
        (itemEnd.isBetween(selectedSlotStart, selectedSlotEnd) ||
          selectedSlotEnd.isSame(itemEnd))
      ) {
        return true;
      }

      // SELECTION OVERLAPS START OR END OF ITEM
      if (
        selectedSlotStart.isBetween(itemStart, itemEnd) ||
        selectedSlotEnd.isBetween(itemStart, itemEnd)
      ) {
        return true;
      }

      return false;
    });

    if (!overLappingEvents.length) {
      setViewerRights([
        ...newViewerRights,
        {
          viewerId: props.viewer.id,
          startDateTime: selectedSlot.start,
          endDateTime: selectedSlot.end,
        },
      ]);
    }
  };

  const allDayHelper = () => {
    return 'Dag';
  };
  const eventComponent = ({ event }) => {
    let label;

    const startDateTime = moment(event.startDateTime);
    const endDateTime = moment(event.endDateTime);

    // console.log(startDateTime.format('HH'));
    // console.log(endDateTime.format('HH'));

    if (
      (startDateTime.isSame(endDateTime, 'day') &&
        startDateTime.isSame(endDateTime, 'hour')) ||
      (startDateTime.isSame(endDateTime.clone().subtract(1, 'days'), 'day') &&
        startDateTime.isSame(endDateTime.clone().subtract(1, 'days'), 'hour'))
    ) {
      label = 'Gehele dag';
    } else if (startDateTime.isSame(endDateTime, 'day')) {
      label = `${startDateTime.format('HH:mm')} - ${endDateTime.format(
        'HH:mm'
      )}`;
    } else {
      label = `${startDateTime.format('DD MMM HH:mm')} - ${endDateTime.format(
        'DD MMM HH:mm'
      )}`;
    }

    return (
      <>
        <strong>{label}</strong>

        <div
          className={`trash absolute cursor-pointer`}
          onClick={() => {
            let selectedEventIndex = viewerRights.findIndex(
              (item) =>
                item.startDateTime === event.startDateTime &&
                item.endDateTime === event.endDateTime
            );

            viewerRights.splice(selectedEventIndex, 1);
          }}
        >
          <Trash2 strokeWidth="2" size={15} />
        </div>
      </>
    );
  };

  return (
    <Modal large {...props}>
      {props.viewer && (
        <>
          <div className="md:hidden block rounded p-5 bg-gray-200 text-xl">
            Het aanpassen van kijkrechten vereist een groter scherm.
          </div>

          <div className="md:block hidden">
            <div className="rounded p-5 mb-5 bg-gray-200 text-xl">
              Selecteer de momenten waarop{' '}
              <span className="font-bold">{props.viewer.name}</span> mag
              meekijken. Klik op een prullenbakje om een moment te verwijderen.
            </div>

            <Calendar
              localizer={localizer}
              selectable
              events={viewerRights}
              defaultDate={now}
              scrollToTime={now}
              defaultView={'week'}
              onSelectSlot={handleSelectSlot}
              style={{ height: '60vh', minHeight: '500px' }}
              startAccessor="startDateTime"
              endAccessor="endDateTime"
              messages={{
                date: 'Datum',
                time: 'Tijd',
                event: 'Event',
                allDay: 'Gehele dag',
                week: 'Week',
                work_week: 'Werkweek',
                day: 'Dag',
                month: 'Maand',
                previous: 'Vorige',
                next: 'Volgende',
                yesterday: 'Gister',
                tomorrow: 'Morgen',
                today: 'Vandaag',
                agenda: 'Lijstweergave',
                noEventsInRange:
                  'Nog geen kijkmomenten toegevoegd in deze periode',
                showMore: (count) => `${count} meer`,
              }}
              views={{ month: true, week: true, day: true }}
              components={{
                event: eventComponent,
                timeGutterHeader: allDayHelper,
              }}
            />

            <Formik
              initialValues={{}}
              onSubmit={async ({ setSubmitting }) => {
                try {
                  await Axios.put(`api/viewer-rights/${props.viewer.id}`, {
                    companyId: props.viewer.companyId,
                    viewerRights,
                  });

                  toast.success(`Kijkrechten ${props.viewer.name} opgeslagen`);

                  props.onRequestClose();
                } catch (error) {}
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Button
                    type="submit"
                    icon="Save"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    title="Opslaan"
                    marginTop
                  />
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </Modal>
  );
}
