import { ErrorMessage, Field, Form, Formik } from 'formik';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';
import Modal from './index';
import toast from 'react-hot-toast';

export default function Component(props) {
  return (
    <Modal {...props}>
      {props.user && (
        <Formik
          initialValues={props.user}
          enableReinitialize
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = 'Dit veld is verplicht';
            }

            if (!values.email) {
              errors.email = 'Dit veld is verplicht';
            }
            if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Ongeldig e-mailadres';
            }

            return errors;
          }}
          onSubmit={async (values) => {
            try {
              await Axios.put(`api/user/${props.user.id}`, {
                name: values.name,
                email: values.email,
                companyId: values.company.id,
              });

              toast.success(`Gebruiker ${values.name} opgeslagen`);

              props.onRequestClose();
            } catch (error) {}
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <FormInputGroup>
                <FormLabel>Naam</FormLabel>
                <Field
                  type="text"
                  name="name"
                  autoFocus
                  component={FormInput}
                />
                <ErrorMessage name="name" component={FormError} />
              </FormInputGroup>

              <FormInputGroup>
                <FormLabel>E-mailadres</FormLabel>
                <Field type="email" name="email" component={FormInput} />
                <ErrorMessage name="email" component={FormError} />
              </FormInputGroup>

              <FormInputGroup>
                <FormLabel>Klant</FormLabel>
                <Field
                  type="text"
                  name="company.name"
                  disabled
                  component={FormInput}
                />
              </FormInputGroup>

              <Button
                type="submit"
                icon="Save"
                disabled={isSubmitting}
                loading={isSubmitting}
                title="Opslaan"
              />
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
}
