import AddUserModal from '../../components/modal/AddUserModal';
import EditUserModal from '../../components/modal/EditUserModal';
import Axios from '../../services/Axios';
import Block from '../../components/ui/Block';
import Button from '../../components/ui/Button';
import Datatable from '../../components/datatable';
import DeleteModal from '../../components/modal/DeleteModal';
import PageError from '../../components/ui/PageError';
import PageHeader from '../../components/dashboard/PageHeader';
import PageLoader from '../../components/ui/PageLoader';
import { mutate } from 'swr';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useUsers } from '../../data';

export default function UsersPage() {
  const [editUserModal, setEditUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [addUserModal, setAddUserModal] = useState(false);

  const [deleteUser, setDeleteUser] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const { users, usersLoading, usersError } = useUsers();

  const tableColumns = [
    {
      name: 'Naam',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'E-mailadres',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Klant',
      selector: 'company.name',
      format: (row) => {
        return row.company?.name ? row.company.name : '';
      },
      sortable: true,
    },
    {
      cell: (user) => (
        <Button
          danger
          inline
          icon="Trash2"
          onClick={() => {
            setDeleteUser(user);
            setDeleteModal(true);
          }}
        />
      ),
      right: true,
    },
  ];

  if (usersError) return <PageError />;
  if (usersLoading) return <PageLoader />;

  return (
    <>
      <AddUserModal
        isOpen={addUserModal}
        title={'Gebruiker toevoegen'}
        onRequestClose={() => {
          setAddUserModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/users`);
        }}
      />

      <EditUserModal
        isOpen={editUserModal}
        title={'Gebruiker aanpassen'}
        onRequestClose={() => {
          setEditUserModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/users`);
        }}
        user={selectedUser}
      />

      <DeleteModal
        isOpen={deleteModal}
        title={'Gebruiker verwijderen'}
        onRequestClose={() => {
          setDeleteModal(false);
        }}
        onSubmit={async () => {
          try {
            await Axios.delete(`api/user/${deleteUser.id}`);

            toast.success(`Gebruiker ${deleteUser.name} verwijderd`);

            mutate(`${process.env.REACT_APP_API_URL}/api/users`);

            setDeleteModal(false);
          } catch (error) {}
        }}
      />

      <PageHeader title="Gebruikers">
        <Button
          inline
          icon="Plus"
          title="Toevoegen"
          onClick={() => {
            setAddUserModal(true);
          }}
        />
      </PageHeader>

      <Block>
        <Datatable
          columns={tableColumns}
          data={users}
          pointerOnHover
          progressPending={usersLoading}
          fixedHeader
          noDataComponent="Geen gebruikers gevonden"
          onRowClicked={(user) => {
            setSelectedUser(user);
            setEditUserModal(true);
          }}
        />
      </Block>
    </>
  );
}
