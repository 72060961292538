import { ErrorMessage, Field, Form, Formik } from 'formik';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';
import moment from 'moment';
import Moment from 'react-moment';

import Modal from './index';
import toast from 'react-hot-toast';

export default function Component(props) {
  moment.locale('nl');

  return (
    <Modal {...props}>
      {props.viewer && (
        <>
          {!props.viewer.allowAlways &&
          props.viewer.viewerRights.length === 0 ? (
            <div className="rounded p-5 bg-gray-200 text-xl">
              Er zijn nog geen kijkmomenten voor{' '}
              <span className="font-bold">{props.viewer.name}</span> ingepland.
              Kies ervoor om{' '}
              <span className="font-bold">{props.viewer.name}</span> altijd te
              laten mogen meeekijken, of plan kijkmomenten in.
            </div>
          ) : (
            <>
              {props.viewer.emailSent && props.viewer.emailSentAt && (
                <div className="rounded p-5 mb-5 bg-gray-200 text-xl">
                  <div className="capitalize-first inline-block">
                    <Moment
                      fromNowDuring={604800000}
                      format="[Op] DD MMMM YYYY [om] HH:mm"
                    >
                      {props.viewer.emailSentAt}
                    </Moment>
                  </div>{' '}
                  is er al een e-mail aan{' '}
                  <span className="font-bold">{props.viewer.name}</span>{' '}
                  verzonden
                </div>
              )}
              <Formik
                initialValues={props.viewer}
                enableReinitialize
                validate={(values) => {
                  const errors = {};

                  if (!values.email) {
                    errors.email = 'Dit veld is verplicht';
                  }
                  if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = 'Ongeldig e-mailadres';
                  }

                  return errors;
                }}
                onSubmit={async (values) => {
                  try {
                    await Axios.post(
                      `api/viewer/${props.viewer.id}/send-email`,
                      {
                        email: values.email,
                        companyId: props.viewer.companyId,
                      }
                    );

                    toast.success(`E-mail aan kijker ${values.name} verzonden`);

                    props.onRequestClose();
                  } catch (error) {}
                }}
              >
                {({ isSubmitting, values }) => (
                  <Form>
                    <FormInputGroup>
                      <FormLabel>Naam</FormLabel>
                      <Field
                        type="text"
                        name="name"
                        disabled
                        component={FormInput}
                      />
                    </FormInputGroup>

                    <FormInputGroup>
                      <FormLabel>E-mailadres</FormLabel>
                      <Field
                        type="email"
                        name="email"
                        autoFocus
                        component={FormInput}
                      />
                      <ErrorMessage name="email" component={FormError} />
                    </FormInputGroup>

                    <Button
                      type="submit"
                      icon="Send"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      title={`${
                        props.viewer.emailSent ? 'Nogmaals ' : ''
                      }Verzenden`}
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}
        </>
      )}
    </Modal>
  );
}
