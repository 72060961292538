import Button from '../../components/ui/Button';
import Modal from './index';

export default function Component(props) {
  const { handleSetting } = props;

  const settings = [
    {
      title: 'Level mode',
      setting: 'levelmode',
      items: [
        {
          name: 'On',
          command: true,
        },
        {
          name: 'Off',
          command: false,
        },
      ],
    },
    {
      title: 'Cambox height',
      setting: 'linak',
      items: [
        {
          name: 'Up',
          command: 'Up',
          type: 'linak',
        },
        {
          name: 'Down',
          command: 'Down',
          type: 'linak',
        },
      ],
    },
  ];

  return (
    <Modal {...props}>
      <>
        <div className="flex flex-wrap align-middle justify-center -mb-5">
          {settings.map((group, groupIndex) => (
            <div className="w-1/2 mb-5" index={groupIndex}>
              <h2 className="m-0 mb-2">{group.title}</h2>

              {group.items?.map((button, buttonIndex) => (
                <Button
                  title={button.name}
                  // icon={button.icon}
                  className={`mb-2 ${groupIndex % 2 ? 'ml-2' : 'mr-2'}`}
                  onClick={() => {
                    if (button.type !== 'linak') {
                      handleSetting(group.setting, button.command);
                    }
                  }}
                  onMouseDown={() => {
                    if (button.type === 'linak') {
                      handleSetting(group.setting, button.command);
                    }
                  }}
                  onMouseUp={() => {
                    if (button.type === 'linak') {
                      handleSetting(group.setting, 'Stop');
                    }
                  }}
                  index={buttonIndex}
                />
              ))}
            </div>
          ))}
        </div>
      </>
    </Modal>
  );
}
