export default function Block(props) {
  return (
    <div
      className={`${
        props.fullWidth ? 'w-full' : 'container'
      } mx-auto px-5 mb-5`}
    >
      <div
        className={`flex flex-wrap justify-between rounded p-5 ${
          props.dark ? 'bg-black text-white' : 'bg-white text-black'
        } ${props.className ? props.className : ''}`}
      >
        {props.children}
      </div>
    </div>
  );
}
