import AddClientModal from '../../components/modal/AddClientModal';
import Block from '../../components/ui/Block';
import Button from '../../components/ui/Button';
import Datatable from '../../components/datatable';
import PageError from '../../components/ui/PageError';
import PageHeader from '../../components/dashboard/PageHeader';
import PageLoader from '../../components/ui/PageLoader';
import { mutate } from 'swr';
import { useCompanies } from '../../data';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

export default function CompaniesPage() {
  const history = useHistory();

  const [addClientModal, setAddClientModal] = useState(false);

  const { companies, companiesLoading, companiesError } = useCompanies();

  const tableColumns = [
    {
      name: 'Naam',
      selector: 'name',
      sortable: true,
    },
  ];

  if (companiesError) return <PageError />;
  if (companiesLoading) return <PageLoader />;

  return (
    <>
      <AddClientModal
        isOpen={addClientModal}
        title={'Klant toevoegen'}
        onRequestClose={() => {
          setAddClientModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/companies`);
        }}
      />

      <PageHeader title="Klanten">
        <Button
          inline
          icon="Plus"
          title="Toevoegen"
          onClick={() => {
            setAddClientModal(true);
          }}
        />
      </PageHeader>

      <Block>
        <Datatable
          columns={tableColumns}
          data={companies}
          progressPending={companiesLoading}
          pointerOnHover
          fixedHeader
          noDataComponent="Geen klanten gevonden"
          onRowClicked={(row) => {
            history.push(`/dashboard/client/${row.id}`);
          }}
        />
      </Block>
    </>
  );
}
