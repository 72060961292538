export default function Card(props) {
  return (
    <div
      className={`${
        props.inline ? 'w-auto' : 'w-full'
      } bg-white rounded relative overflow-hidden mx-auto border border-solid border-gray-300 ${
        props.className ? props.className : ''
      }`}
    >
      {props.disabled && (
        <div className="absolute z-50 inset-0 bg-gray-200 opacity-50 cursor-not-allowed"></div>
      )}

      {props.header && (
        <>
          <div
            className={`w-full p-5 flex flex-wrap justify-between bg-br-gold`}
          >
            <div
              className={`flex items-center ${
                props.buttons ? 'h-14' : ''
              } text-white`}
            >
              <h2 className="m-0">{props.header}</h2>
            </div>

            {props.buttons && (
              <div
                className={`${
                  props.buttons ? 'h-14' : ''
                } flex justify-end items-center`}
              >
                {props.buttons}
              </div>
            )}
          </div>
        </>
      )}

      <div className="p-5">{props.children}</div>
    </div>
  );
}
