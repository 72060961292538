import { ErrorMessage, Field, Form, Formik } from 'formik';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';
import { useState } from 'react';

export default function PasswordLogin(props) {
  const [validLogin, setValidLogin] = useState(null);

  return (
    <>
      <div className="flex flex-grow justify-center items-center container mx-auto px-5 mb-5">
        <div className="w-full md:w-1/2 bg-white text-black rounded p-5">
          <Formik
            initialValues={{ password: '' }}
            validate={(values) => {
              const errors = {};

              if (!values.password) {
                errors.password = 'This field is required';
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                const response = await Axios.post(
                  `api/live/${props.stream}/${props.viewer}`,
                  {
                    password: values.password,
                  }
                );

                resetForm();

                if (response && response.data?.data?.viewer?.signedStreamUrl) {
                  setValidLogin(true);
                  props.setStreamUrl(response.data.data.viewer.signedStreamUrl);
                } else {
                  setValidLogin(false);
                }
              } catch (error) {}
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormInputGroup>
                  <FormLabel>Password</FormLabel>

                  <Field
                    type="password"
                    name="password"
                    component={FormInput}
                  />
                  <ErrorMessage name="password" component={FormError} />
                  {validLogin === false && (
                    <div className="text-red-500">
                      Invalid password provided
                    </div>
                  )}
                </FormInputGroup>

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  title="Login"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
