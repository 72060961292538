export default function FormSelect({ field, form, ...props }) {
  return (
    <select
      {...field}
      {...props}
      className="w-full border-solid border rounded px-3 py-2 border-black"
    >
      {props.children}
    </select>
  );
}
