import AddCamBoxModal from '../../components/modal/AddCamBoxModal';
import Block from '../../components/ui/Block';
import Button from '../../components/ui/Button';
import Datatable from '../../components/datatable';
import PageError from '../../components/ui/PageError';
import PageHeader from '../../components/dashboard/PageHeader';
import PageLoader from '../../components/ui/PageLoader';
import { mutate } from 'swr';
import { useCamBoxes, useLoggedInUser } from '../../data';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

export default function CamBoxesPage() {
  const history = useHistory();

  const [addCamBoxModal, setAddCamBoxModal] = useState(false);

  const { camboxes, camboxesLoading, camboxesError } = useCamBoxes();

  const { loggedInUser, loggedInUserLoading } = useLoggedInUser();

  const tableColumns = [
    {
      name: '',
      selector: 'onlineStatus',
      sortable: false,
      width: '3rem',
      format: (row) => {
        if (row.onlineStatus) {
          return (
            <>
              <div className="h-4 w-4 mr-2 rounded-full bg-green-600"></div>
            </>
          );
        } else {
          return (
            <>
              <div className="h-4 w-4 mr-2 rounded-full bg-red-600"></div>
            </>
          );
        }
      },
    },
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Client',
      omit: !loggedInUserLoading && loggedInUser.role !== 'Admin',
      sortable: true,
      selector: 'company',
      format: (row) => {
        if (row.company) return row.company.name;
      },
    },
    {
      name: 'Connected to',
      omit: !loggedInUserLoading && loggedInUser.role !== 'Admin',
      sortable: false,
      selector: 'type',
      format: (row) => {
        if (row.type === 'Cambox' && row.returnbox && row.returnbox.name) {
          return `Returnbox: ${row.returnbox.name}`;
        }
      },
    },
  ];

  if (camboxesError) return <PageError />;
  if (camboxesLoading || loggedInUserLoading) return <PageLoader />;

  return (
    <>
      <AddCamBoxModal
        isOpen={addCamBoxModal}
        title={'Add cambox'}
        onRequestClose={() => {
          setAddCamBoxModal(false);
          mutate(`${process.env.REACT_APP_API_URL}/api/camboxes`);
        }}
      />

      <PageHeader title="Cambox">
        {/* {loggedInUser.role === 'Admin' && (
          <Button
            inline
            icon="Plus"
            title="Add"
            onClick={() => {
              setAddCamBoxModal(true);
            }}
          />
        )} */}
      </PageHeader>

      <Block>
        <Datatable
          columns={tableColumns}
          data={camboxes}
          progressPending={camboxesLoading}
          pointerOnHover
          fixedHeader
          noDataComponent="No Cambox found"
          onRowClicked={(row) => {
            history.push(
              `/dashboard/${row.type === 'Cambox' ? 'cambox' : 'returnbox'}/${
                row.uuid
              }`
            );
          }}
        />
      </Block>
    </>
  );
}
