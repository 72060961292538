import AuthService from '../../services/auth';
import LoginForm from '../../components/dashboard/LoginForm';
import PageLoader from '../../components/ui/PageLoader';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function LoginPage(props) {
  const history = useHistory();

  const { token } = useParams();

  useEffect(() => {
    async function logIn() {
      let loggedIn;
      if (token !== undefined) loggedIn = await AuthService.logIn(token);

      if (loggedIn) {
        history.push('/');
      } else {
        history.push('/login');
      }
    }

    if (token) logIn();
  }, [token, history]);

  if (token) return <PageLoader />;

  return (
    <>
      <div className="flex w-screen h-screen">
        <div className="w-1/2 lg:block hidden h-full">
          <img
            src="/assets/image/luchtballon.jpg"
            alt=""
            className="object-cover object-center w-full h-full"
          />

          <div className="absolute left-5 bottom-5 text-white">
            Live vanuit een heteluchtballon
          </div>
        </div>
        <div className="lg:w-1/2 w-full h-full bg-black">
          <div className="flex h-full items-center justify-center">
            <LoginForm />
          </div>
        </div>
      </div>
    </>
  );
}
