import axios from 'axios';
import toast from 'react-hot-toast';

// import logOut from '../services/auth';

const jwt = localStorage.getItem('token');

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/`;
if (jwt) axios.defaults.headers.common = { Authorization: `Bearer ${jwt}` };

const successHandler = (response) => response;

const errorHandler = (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  // if (
  //   error.response.status === 401 &&
  //   error.response.config.url.endsWith('/api/me')
  // )
  //   logOut();

  if (
    error.response?.status &&
    error.response.data?.error &&
    !error.response.data?.validation
  ) {
    toast.error(error.response.data.error);
  } else {
    if (expectedError) {
      toast.error('Er is helaas iets fout gegaan');
    }
  }

  return Promise.reject(error);
};

axios.interceptors.response.use(successHandler, errorHandler);

const axiosMethods = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
};

export default axiosMethods;
