import Block from '../../components/ui/Block';

export default function PageHeader(props) {
  return (
    <>
      <Block className="bg-br-gold text-white">
        <div
          className={`${
            props.children ? 'w-2/3' : 'w-full'
          } min-h-14 flex items-center`}
        >
          <h1 className="m-0">{props.title}</h1>
        </div>

        {props.children && (
          <div className="w-1/3 h-14 flex justify-end items-center">
            {props.children}
          </div>
        )}
      </Block>
    </>
  );
}
