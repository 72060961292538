import { useReturnBox } from '../../data';

import Moment from 'react-moment';

import Button from '../../components/ui/Button';
import Datatable from '../../components/datatable';

import Card from '../../components/ui/Card';
import Container from '../../components/ui/Container';

import PlayerReturnBoxModal from '../../components/modal/PlayerReturnBoxModal';
import StatisticsReturnBoxModal from '../../components/modal/StatisticsReturnBoxModal';
import SettingsReturnBoxModal from '../../components/modal/SettingsReturnBoxModal';
import EditReturnBoxModal from '../../components/modal/EditReturnBoxModal';

import PageError from '../../components/ui/PageError';
import PageHeader from '../../components/dashboard/PageHeader';
import PageLoader from '../../components/ui/PageLoader';
import Block from '../../components/ui/Block';

import { mutate } from 'swr';

import { useRouteMatch } from 'react-router-dom';
import React, { useState, useContext, useCallback, useEffect } from 'react';

import { SocketContext } from '../../context/socket';

import { useLoggedInUser } from '../../data';

export default function ReturnBoxPage() {
  const { params } = useRouteMatch();

  const { loggedInUser, loggedInUserLoading } = useLoggedInUser();

  const streamsTableColumns = [
    {
      name: '',
      selector: 'status',
      sortable: false,
      width: '3rem',
      format: (row) => {
        if (row.status) {
          return (
            <>
              <div className="h-4 w-4 mr-2 rounded-full bg-green-600"></div>
            </>
          );
        } else {
          return (
            <>
              <div className="h-4 w-4 mr-2 rounded-full bg-red-600"></div>
            </>
          );
        }
      },
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: false,
    },
    {
      cell: (stream) => (
        <>
          {stream.status && (
            <Button
              inline
              icon="Play"
              onClick={() => {
                const newWindow = window.open(
                  `https://webrtc.bcrflex.com/Returnbox/play.html?name=${returnbox.uuid}-${stream.type}&token=${stream.token}`,
                  '_blank',
                  'noopener, noreferrer'
                );
                if (newWindow) newWindow.opener = null;

                // setSelectedStream(stream);
                // setPlayerReturnBoxModal(true);
              }}
            />
          )}
        </>
      ),
      right: true,
    },
  ];

  const socket = useContext(SocketContext);

  const [settingsReturnBoxModal, setSettingsReturnBoxModal] = useState(false);
  const [playerReturnBoxModal, setPlayerReturnBoxModal] = useState(false);
  const [selectedStream, setSelectedStream] = useState(false);
  const [statisticsReturnBoxModal, setStatisticsReturnBoxModal] =
    useState(false);
  const [editReturnBoxModal, setEditReturnBoxModal] = useState(false);

  const { returnbox, returnboxLoading, returnboxError } = useReturnBox(
    params.returnbox
  );

  const [socketAuthorized, setSocketAuthorized] = useState(false);

  const handleAuthorized = useCallback((status) => {
    setSocketAuthorized(status);
  }, []);

  const handleDisconnect = useCallback(() => {
    setSocketAuthorized(false);
  }, []);

  const handleSocketMessage = useCallback((message) => {
    const { topic, payload } = message;

    switch (topic) {
      case 'tally':
        // if (payload.value === true) setLedRingValue(payload.color);

        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.connect();

      socket.on('connect', () => {
        socket.emit('requestAccessToReturnBox', params.returnbox);
      });

      socket.on('authorized', handleAuthorized);
      socket.on('disconnected', handleDisconnect);

      socket.on(params.returnbox, handleSocketMessage);

      return () => {
        socket.off('authorized', handleAuthorized);
        socket.off('disconnected', handleDisconnect);

        socket.off(params.returnbox, handleSocketMessage);

        socket.disconnect();
      };
    }
  }, [
    socket,
    handleAuthorized,
    handleDisconnect,
    params.returnbox,
    handleSocketMessage,
  ]);

  const handleSetting = useCallback((setting, command) => {
    socket.emit('returnbox', {
      returnBoxUUID: params.returnbox,
      topic: 'setting',
      payload: { setting, command },
    });
  }, []);

  if (returnboxError) return <PageError />;
  if (returnboxLoading || loggedInUserLoading || !socketAuthorized)
    return <PageLoader />;

  return (
    <>
      <PlayerReturnBoxModal
        isOpen={playerReturnBoxModal}
        title={'Play'}
        onRequestClose={() => {
          setPlayerReturnBoxModal(false);
        }}
        returnbox={returnbox}
        selectedStream={selectedStream}
      />

      <StatisticsReturnBoxModal
        isOpen={statisticsReturnBoxModal}
        title={'Statistics'}
        onRequestClose={() => {
          setStatisticsReturnBoxModal(false);
        }}
        returnbox={returnbox}
      />

      <SettingsReturnBoxModal
        isOpen={settingsReturnBoxModal}
        title={'Settings'}
        onRequestClose={() => {
          setSettingsReturnBoxModal(false);
        }}
        handleSetting={handleSetting}
      />

      {loggedInUser?.role === 'Admin' && (
        <EditReturnBoxModal
          isOpen={editReturnBoxModal}
          title={'Edit Returnbox'}
          onRequestClose={() => {
            setEditReturnBoxModal(false);
            mutate(
              `${process.env.REACT_APP_API_URL}/api/returnbox/${returnbox.uuid}`
            );
          }}
          returnbox={returnbox}
        />
      )}

      <PageHeader title={`Returnbox: ${returnbox.name}`}>
        {returnbox.onlineStatus && (
          <>
            <Button
              inline
              icon="BarChart"
              title="Statistics"
              onClick={() => {
                setStatisticsReturnBoxModal(true);
              }}
              className="mr-2"
            />

            {/* <Button
              inline
              icon="Settings"
              title="Settings"
              onClick={() => {
                setSettingsReturnBoxModal(true);
              }}
              className="mr-2"
            /> */}
          </>
        )}

        {loggedInUser?.role === 'Admin' && (
          <Button
            inline
            icon="Edit"
            title="Edit"
            onClick={() => {
              setEditReturnBoxModal(true);
            }}
            className="mr-2"
          />
        )}
      </PageHeader>

      <Block className="bg-white text-black">
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center">
            {returnbox.onlineStatus && (
              <>
                <div className="h-4 w-4 mr-2 rounded-full bg-green-600"></div>

                <h4 className="m-0">
                  Online
                  <span className="sm:inline hidden">
                    {' '}
                    - since{' '}
                    <Moment
                      fromNowDuring={3600}
                      locale="en"
                      format="MMMM DD YYYY[,] HH:mm"
                    >
                      {returnbox.onlineSince}
                    </Moment>
                  </span>
                </h4>
              </>
            )}

            {!returnbox.onlineStatus && (
              <>
                <div className="h-4 w-4 mr-2 rounded-full bg-red-600"></div>

                <h4 className="m-0">
                  Offline
                  <span className="sm:inline hidden">
                    {' '}
                    - since{' '}
                    <Moment
                      fromNowDuring={3600}
                      locale="en"
                      format="MMMM DD YYYY[,] HH:mm"
                    >
                      {returnbox.offlineSince}
                    </Moment>
                  </span>
                </h4>
              </>
            )}
          </div>
        </div>
      </Block>

      <Container>
        {returnbox.onlineStatus && (
          <div className="w-full flex md:flex-row flex-col">
            <div className="md:w-1/2 w-full md:mr-5 md:mb-0 mr-0 mb-5">
              <div className="aspect-w-16 aspect-h-9 mb-5 rounded overflow-hidden bg-black">
                <img
                  src={`https://webrtc.bcrflex.com/Returnbox/previews/${
                    returnbox.uuid
                  }-video.png?${global.Date.now()}`}
                  alt=""
                  className="object-contain"
                />
              </div>

              <div className="flex md:flex-row flex-col w-full md:mb-5 mb-0"></div>
            </div>

            <div className="flex flex-wrap flex-col md:w-1/2 w-full">
              <div className="flex md:flex-row flex-col w-full md:mb-5 mb-0">
                <Card
                  header="Streams"
                  inline
                  className="w-full mr-0 mb-5"
                  disabled={!returnbox.onlineStatus}
                >
                  <Datatable
                    columns={streamsTableColumns}
                    data={[
                      {
                        type: 'ifb',
                        name: 'Ifb audio',
                        status: returnbox.ifb,
                        token: returnbox.ifbToken,
                      },
                      {
                        type: 'audio',
                        name: 'Audio',
                        status: returnbox.audio,
                        token: returnbox.audioToken,
                      },
                      {
                        type: 'video',
                        name: 'Video',
                        status: returnbox.video,
                        token: returnbox.videoToken,
                      },
                    ]}
                    progressPending={returnboxLoading}
                    fixedHeader
                    noDataComponent="No streams found"
                  />
                </Card>
              </div>

              {/* <div className="flex md:flex-row flex-col w-full md:mb-5 mb-0">
              <Card
                header="Tally status"
                inline
                className="w-full mr-0 mb-5"
                disabled={!returnbox.onlineStatus}
              ></Card>
            </div> */}
            </div>
          </div>
        )}
      </Container>
    </>
  );
}
