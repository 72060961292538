import Axios from '../services/Axios';
import useSWR from 'swr';

const swrFetcher = (url) => Axios.get(url).then((res) => res.data);

export function useApiStatus() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/status`,
    swrFetcher
  );

  return {
    apiStatus: data,
    apiLoading: !error && !data,
    apiError: error,
  };
}

export function useLive(stream, viewer) {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/live/${stream}/${viewer}`,
    swrFetcher
  );

  return {
    stream: data?.data,
    streamLoading: !error && !data,
    streamError: error,
  };
}

export function useLoggedInUser() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/me`,
    swrFetcher
  );

  return {
    loggedInUser: data?.data,
    loggedInUserLoading: !error && !data,
    loggedInUserError: error,
  };
}

export function useUser(id) {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/user/${id}`,
    swrFetcher
  );

  return {
    user: data?.data,
    userLoading: !error && !data,
    userError: error,
  };
}

export function useUsers() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/users`,
    swrFetcher
  );

  return {
    users: data?.data,
    usersLoading: !error && !data,
    usersError: error,
  };
}

export function useCompany(id) {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/company/${id}`,
    swrFetcher
  );

  return {
    company: data?.data,
    companyLoading: !error && !data,
    companyError: error,
  };
}

export function useCompanies() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/companies`,
    swrFetcher
  );

  return {
    companies: data?.data,
    companiesLoading: !error && !data,
    companiesError: error,
  };
}
export function useStream(id) {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/stream/${id}`,
    swrFetcher
  );

  return {
    stream: data?.data,
    streamLoading: !error && !data,
    streamError: error,
  };
}

export function useStreams() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/streams`,
    swrFetcher
  );

  return {
    streams: data?.data,
    streamsLoading: !error && !data,
    streamsError: error,
  };
}

export function useCamBox(id) {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/cambox/${id}`,
    swrFetcher,
    { refreshInterval: 5000 }
  );

  return {
    cambox: data?.data,
    camboxLoading: !error && !data,
    camboxError: error,
  };
}

export function useCamBoxes() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/camboxes`,
    swrFetcher,
    { refreshInterval: 5000 }
  );

  return {
    camboxes: data?.data,
    camboxesLoading: !error && !data,
    camboxesError: error,
  };
}

export function useReturnBoxes() {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/returnboxes`,
    swrFetcher,
    { refreshInterval: 5000 }
  );

  return {
    returnboxes: data?.data,
    returnboxesLoading: !error && !data,
    returnboxesError: error,
  };
}

export function useReturnBox(id) {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/returnbox/${id}`,
    swrFetcher,
    { refreshInterval: 5000 }
  );

  return {
    returnbox: data?.data,
    returnboxLoading: !error && !data,
    returnboxError: error,
  };
}
