import Block from '../../components/ui/Block';

export default function Header(props) {
  return (
    <>
      <Block className="md:mt-10 mt-5 shadow-lg">
        <div className="md:w-1/3 w-full flex md:justify-start justify-center">
          {props.stream.stream.company.logo && (
            <img
              src={`${process.env.REACT_APP_API_URL}/assets/${props.stream.stream.company.logo}`}
              alt=""
              className="h-14"
            />
          )}

          {!props.stream.stream.company.logo && (
            <h1
              className="m-0"
              style={{
                color:
                  props.stream.stream.company.mainColor !== '#ffffff'
                    ? props.stream.stream.company.mainColor
                    : '#000000',
              }}
            >
              {props.stream.stream.company.name}
            </h1>
          )}
        </div>

        <div className="md:w-2/3 md:mt-0 mt-5 w-full flex md:justify-end justify-center items-center md:flex-row flex-col">
          <h1
            className="leading-none m-0 md:text-left text-center"
            style={{
              color:
                props.stream.stream.company.mainColor !== '#ffffff'
                  ? props.stream.stream.company.mainColor
                  : '#000000',
            }}
          >
            {props.stream.stream.company.logo && (
              <>
                {props.stream.stream.company.name} - {props.stream.stream.name}
              </>
            )}

            {!props.stream.stream.company.logo && (
              <>{props.stream.stream.name}</>
            )}
          </h1>
        </div>
      </Block>
    </>
  );
}
