import { useState } from 'react';
import * as FeatherIcon from 'react-feather';

export default function Button(props) {
  const { className } = props;

  const [pressedEffect, setPressedEffect] = useState(false);

  const Icon = function () {
    const Icon =
      FeatherIcon[props.loading ? 'Loader' : props.icon || 'ArrowRight'];

    return (
      <>
        <span className={`inline-block ${props.loading ? 'animate-spin' : ''}`}>
          <Icon strokeWidth="2" size={16} />
        </span>
      </>
    );
  };

  return (
    <button
      {...props}
      type={props.type ? props.type : 'button'}
      className={`${pressedEffect ? 'animate-press' : ''} ${
        props.inline ? '' : 'w-full'
      } bg-black text-white p-2 uppercase rounded outline-none focus:outline-none hover:opacity-90 flex items-center justify-center ${
        props.danger ? 'bg-red-600' : ''
      } ${props.disabled ? 'cursor-not-allowed opacity-50' : ''} ${
        props.marginTop ? 'mt-5' : ''
      } ${className ? className : ''}`}
      onClick={() => {
        setPressedEffect(true);

        // RUN PASSED ONCLICK FUNCTION
        if (props.onClick) props.onClick();
      }}
      onAnimationEnd={() => setPressedEffect(false)}
    >
      {(props.icon || (!props.icon && props.inline)) && <Icon />}

      {props.title && (
        <span
          className={`${props.icon ? 'ml-2' : 'ml-0'} ${
            props.inline ? 'sm:block hidden' : ''
          }`}
        >
          {props.title}
        </span>
      )}
    </button>
  );
}
