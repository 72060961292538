import Modal from './index';

import ReactSpeedometer from 'react-d3-speedometer';

import Moment from 'react-moment';

export default function Component(props) {
  return (
    <Modal {...props}>
      <>
        <div className="flex flex-wrap align-middle justify-center -mb-5">
          {props.cambox && (
            <>
              {props.cambox.CamBoxStatistics?.map(
                (statistic, statisticIndex) => (
                  <>
                    {statistic.name === 'piTemperature' && (
                      <div className="w-1/2 mb-5" index={statisticIndex}>
                        <h2 className="m-0">CPU temperature</h2>
                        <div className="mb-5">
                          Updated:{' '}
                          <Moment fromNow locale="en">
                            {statistic.updatedAt}
                          </Moment>
                        </div>
                        <div className="m-0 mb-2">
                          <ReactSpeedometer
                            maxValue={90}
                            customSegmentStops={[0, 70, 80, 90]}
                            value={statistic.value}
                            needleColor="black"
                            startColor="green"
                            segmentColors={['green', 'orange', 'red']}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )
              )}
            </>
          )}
        </div>
      </>
    </Modal>
  );
}
