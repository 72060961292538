import { ErrorMessage, Field, Form, Formik } from 'formik';

import Axios from '../../services/Axios';
import Button from '../../components/ui/Button';
import FormError from '../../components/ui/FormError';
import FormInput from '../../components/ui/FormInput';
import FormInputGroup from '../../components/ui/FormInputGroup';
import FormLabel from '../../components/ui/FormLabel';
import FormSelect from '../../components/ui/FormSelect';
import Modal from './index';
import toast from 'react-hot-toast';

import { useEffect, useState } from 'react';

export default function Component(props) {
  const [clearPassword, setClearPassword] = useState(false);

  useEffect(() => {
    setClearPassword(false);
  }, [props.viewer]);

  return (
    <Modal {...props}>
      {props.viewer && (
        <Formik
          initialValues={props.viewer}
          enableReinitialize
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = 'Dit veld is verplicht';
            }
            if (!values.allowAlways === undefined) {
              errors.allowAlways = 'Dit veld is verplicht';
            }

            return errors;
          }}
          onSubmit={async (values) => {
            try {
              await Axios.put(`api/viewer/${props.viewer.id}`, {
                name: values.name,
                allowAlways: values.allowAlways,
                password: values.password,
                clearPassword,
                companyId: props.viewer.companyId,
              });

              toast.success(`Kijker ${values.name} opgeslagen`);

              props.onRequestClose();
            } catch (error) {}
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <FormInputGroup>
                <FormLabel>Naam</FormLabel>
                <Field
                  type="text"
                  name="name"
                  autoFocus
                  component={FormInput}
                />
                <ErrorMessage name="name" component={FormError} />
              </FormInputGroup>

              <FormInputGroup>
                <FormLabel>
                  Mag {values.name ? values.name : 'de kijker'} altijd
                  meekijken?
                </FormLabel>

                <Field as="select" name="allowAlways" component={FormSelect}>
                  <option value="" disabled>
                    ...
                  </option>

                  <option value="true">Ja</option>
                  <option value="false">Nee</option>
                </Field>

                <ErrorMessage name="allowAlways" component={FormError} />
              </FormInputGroup>

              {values.passwordId && !clearPassword && (
                <FormInputGroup>
                  <Button
                    icon="UserMinus"
                    title="Verwijder wachtwoord"
                    danger={true}
                    onClick={() => {
                      setClearPassword(true);
                    }}
                  />
                </FormInputGroup>
              )}

              {(!values.passwordId || clearPassword) && (
                <FormInputGroup>
                  <FormLabel>Wachtwoord (optioneel)</FormLabel>

                  <Field
                    type="password"
                    name="password"
                    component={FormInput}
                  />
                  <ErrorMessage name="password" component={FormError} />
                </FormInputGroup>
              )}

              <Button
                type="submit"
                icon="Save"
                disabled={isSubmitting}
                loading={isSubmitting}
                title="Opslaan"
              />
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
}
