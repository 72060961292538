import Card from '../ui/Card';

export default function PageError(props) {
  return (
    <div
      className={`w-screen px-5 ${
        props.screen ? 'h-screen' : 'h-full'
      } flex items-center justify-center text-center`}
    >
      <Card inline>
        {props.children
          ? props.children
          : 'Er is helaas iets fout gegaan bij het ophalen van deze pagina'}
      </Card>
    </div>
  );
}
